import { configureStore } from "@reduxjs/toolkit"
import APIResponseSlice from "./slices/APIResponseSlice"
import MSALSlice from "./slices/MSALSlice"
import FilterSlice from "./slices/FilterSlice"

export const store = configureStore({
  reducer: {
    MSALAuth: MSALSlice,
    APIResponse: APIResponseSlice,
    Filter: FilterSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})