import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { changeSelectedProducts } from "../../store/slices/FilterSlice"
import { getCateringAnalytics } from "../../services/ApiServices"
import { DownloadTableExcel } from "react-export-table-to-excel"
import { useDispatch, useSelector } from "react-redux"
import { handleError } from "../../const"
import Loader from "../Loader/Loader"
import moment from "moment"
import "./Catering.css"

import CateringDonutChart from "../Charts/CateringDoughnutChart/CateringDoughnutChart"
import BarChart from "../Charts/BarChart/BarChart"

export default function Catering() {
    const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)
    const selectedProducts = useSelector((state) => state.Filter.SelectedProducts)

    const [isLoading, setIsLoading] = useState(false)
    const [cateringData, setCateringData] = useState([])
    const [accordionIndex, setAccordionIndex] = useState([null])
    const [inputCateringSearch, setInputCateringSearch] = useState("")

    const dispatch = useDispatch()
    const allCateringRef = useRef()
    const backgroundColors = useMemo(() => (["#ebcb2f", "#36A2EB", "#1aad45", "#FF6384", "#c57878", "#70dbdf"]), [])

    // const backgroundColors = useMemo(() => {
    //     if (cateringData?.CostByCostCenter && cateringData?.CostByCostCenter?.length > 0) {
    //         return cateringData?.CostByCostCenter?.map((_, i) => getRandomColor())
    //     } else {
    //         return []
    //     }
    // }, [cateringData?.CostByCostCenter])

    const handleFistLevelSelect = (firstLevelIndex) => {
        setAccordionIndex((prev) => [prev[0] !== firstLevelIndex ? firstLevelIndex : null])
    }

    const toLocaleString = (number) => {
        if (number) {
            return number?.toLocaleString("da-DK")
        } else {
            return ""
        }
    }

    const searchedCateringAnalytics = useMemo(() => {
        if (cateringData?.OrdersByDate && cateringData?.OrdersByDate?.length > 0) {
            const filteredOrdersByDate = cateringData?.OrdersByDate?.map((date) => (
                (date?.Orders ?? [])?.filter((user) => (
                    user?.CostCenter?.Name?.toLowerCase()?.includes(inputCateringSearch?.toLowerCase()) ||
                    user?.Location?.Name?.toLowerCase()?.includes(inputCateringSearch?.toLowerCase()) ||
                    user?.MeetingRoom?.Name?.toLowerCase()?.includes(inputCateringSearch?.toLowerCase())
                ))
            ))

            const updatedOrdersByDate = cateringData?.OrdersByDate?.map((date, index) => ({
                ...date,
                Orders: filteredOrdersByDate[index] || []
            }))

            return {
                ...cateringData,
                OrdersByDate: updatedOrdersByDate
            }
        } else {
            return { ...cateringData, OrdersByDate: [] }
        }
    }, [inputCateringSearch, cateringData])

    const data = useMemo(() => {
        const data = {
            FromDate: ApiObjectState?.fromDate,
            ToDate: ApiObjectState?.toDate,
            locationIds: ApiObjectState?.locationIds,
            productIds: ApiObjectState?.productsIds,
            companyIds: ApiObjectState?.companiesIds,
            departmentIds: ApiObjectState?.costCenterIds,
        }
        return data
    }, [ApiObjectState])

    const fetchCateringAnalytics = useCallback((data) => {
        let subscribed = true

        if (data && (data?.FromDate || data?.ToDate || data?.locationIds || data?.productsIds || data?.companiesIds || data?.departmentIds)) {
            setIsLoading(true)
            getCateringAnalytics(data).then((response) => {
                if (subscribed) {
                    if (typeof response === "object" && response && Object.keys(response)?.length >= 0 && !response?.ExceptionMessage && !response?.Message) {
                        setCateringData(response)
                    } else {
                        setCateringData((prev) => []?.length > 0 ? [] : prev)
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    setCateringData((prev) => []?.length > 0 ? [] : prev)
                    handleError(error)
                }
            }).finally(() => {
                if (subscribed) {
                    setIsLoading(false)
                }
            })
        }

        return () => {
            subscribed = false
        }
    }, [])

    const handleProductsClick = useCallback((product, selectedProducts) => {
        if (product) {
            let newSelectedProducts = []
            if (!selectedProducts.includes(product)) {
                newSelectedProducts = [...selectedProducts, product]
            } else if (selectedProducts.includes(product)) {
                newSelectedProducts = selectedProducts.filter((d) => d !== product)
            }
            dispatch(changeSelectedProducts(newSelectedProducts))
        }
    }, [dispatch])

    const graphLabel = useMemo(() => (
        (cateringData?.OrdersByProduct ?? [])?.map((d) => d?.Product?.Name)
    ), [cateringData?.OrdersByProduct])
    const graphData = useMemo(() => (
        (cateringData?.OrdersByProduct ?? [])?.map((d) => d?.OrderCount)
    ), [cateringData?.OrdersByProduct])
    const graphIds = useMemo(() => (
        (cateringData?.OrdersByProduct ?? [])?.map((d) => d?.Product?.Id)
    ), [cateringData?.OrdersByProduct])
    const onBarClick = useCallback((product) => (
        handleProductsClick(product, selectedProducts)
    ), [handleProductsClick, selectedProducts])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchCateringAnalytics(data)
        }, 100)

        return () => clearTimeout(timeoutId)
    }, [data, fetchCateringAnalytics])

    return (
        <>
            {isLoading && <Loader />}
            <div className="tab-pane catering-tab">
                {/* Top Three Card */}
                <div className="row d-flex flex-wrap">
                    {/*============================= Total Orders =============================*/}
                    <div className="col-xl-2 col-lg-4 col-md-4">
                        <div className="card counts shadownone">
                            <div className="card-body p-4">
                                <div className="title mb-1">Total Orders</div>
                                <div className="digit">{cateringData?.TotalOrders ? cateringData?.TotalOrders : 0}</div>
                            </div>
                        </div>
                    </div>

                    {/*============================= Total Cost =============================*/}
                    <div className="col-xl-7 col-lg-8 col-md-8">
                        <div className="card counts shadownone">
                            <div className="card-body p-4">
                                <div className="d-flex justify-content-between align-items center">
                                    <div>
                                        <div className="title mb-1">Total Cost</div>
                                        <div className="digit">Kr {cateringData?.CostTotal ? toLocaleString(cateringData?.CostTotal) : 0}</div>
                                    </div>
                                    <div>
                                        <div className="title mb-1">Total Cost <span style={{ color: "var(--primary-50-color)" }}>Internal</span></div>
                                        <div className="digit">Kr {cateringData?.CostInternal ? toLocaleString(cateringData?.CostInternal) : 0}</div>
                                    </div>
                                    <div>
                                        <div className="title mb-1">Total Cost <span style={{ color: "var(--primary-50-color)" }}>External</span></div>
                                        <div className="digit">Kr {cateringData?.CostExternal ? toLocaleString(cateringData?.CostExternal) : 0}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*============================= Total Guest =============================*/}
                    <div className="col-xl-3 col-lg-6  col-md-12">
                        <div className="card shadownone" style={{ backgroundColor: "var(--warning-25-color)", border: "1px solid var(--warning-color)" }}>
                            <div className="card-body p-4">
                                <div className="d-flex justify-content-between align-items center">
                                    <div className="text-ellipsis overflow-hidden" style={{ marginRight: "10px" }}>
                                        <div className="title ">Total Guest</div>
                                        <div style={{ color: "var(--warning-color)", fontSize: "38px", fontWeight: 300 }}>
                                            {cateringData?.GuestsTotal ? cateringData?.GuestsTotal : 0}
                                        </div>
                                    </div>
                                    <div className="text-ellipsis" style={{ marginRight: "10px" }}>
                                        <div className="title">Internal</div>
                                        <div style={{ color: "var(--warning-color)", fontSize: "38px", fontWeight: 300 }}>
                                            {cateringData?.GuestsInternal ? cateringData?.GuestsInternal : 0}
                                        </div>
                                    </div>
                                    <div className="text-ellipsis">
                                        <div className="title">External</div>
                                        <div style={{ color: "var(--warning-color)", fontSize: "38px", fontWeight: 300 }}>
                                            {cateringData?.GuestsExternal ? cateringData?.GuestsExternal : 0}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Charts */}
                <div className="row">
                    {/*============================= Bar Chart =============================*/}
                    <div className="col-xl-7 col-lg-7 col-md-12">
                        <div className="card">
                            <div className="card-body p-4 h300">
                                <div className="row mb-3">
                                    <div>
                                        <h4 className="mb-0">Orders</h4>
                                    </div>
                                </div>
                                <div className="w-100 d-flex justify-content-center" style={{ height: "195px" }}>
                                    <BarChart
                                        graphToolTipLabel="Orders"
                                        graphLabel={graphLabel}
                                        graphData={graphData}
                                        graphIds={graphIds}
                                        onBarClick={onBarClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*============================= Donut Chart =============================*/}
                    <div className="col-xl-5 col-lg-5 col-md-12">
                        <div className="card">
                            <div className="card-body p-4 h300">

                                <h4 className="mb-2">Costcenter</h4>
                                <div className="w-100 d-flex overflow-hidden" style={{ height: "220px" }}>
                                    <div className="d-flex align-items-center justify-content-center" style={{ width: "70%" }}>
                                        <CateringDonutChart
                                            graphData={cateringData}
                                            backgroundColors={backgroundColors}
                                        />
                                    </div>

                                    <div className="legend-list h-100" style={{ width: "30%" }}>
                                        {cateringData?.CostByCostCenter && cateringData?.CostByCostCenter?.length > 0 && (
                                            cateringData?.CostByCostCenter?.map((item, itemIndex) =>
                                                <div key={itemIndex} className="legend" title={item?.CostCenter?.Name ? item?.CostCenter?.Name : "--"}>
                                                    <div className="legend-color" style={{ backgroundColor: backgroundColors[itemIndex] ?? "" }} />
                                                    <div className="legend-info flex-fill overflow-hidden">
                                                        <label className="legend-label">
                                                            {item?.CostCenter?.Name ? item?.CostCenter?.Name : "--"}
                                                        </label>
                                                        <span className="legend-value">
                                                            {`Kr ${toLocaleString(item?.CostTotal ?? 0)}`}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Line Chart */}
                    {/* <div className="col-xl-3 col-lg-8 col-md-14">
                        <div className="card">
                            <div className="card-body p-4 h300">
                                <div className="row mb-3">
                                    <div className="col-6">
                                        <h4 className="mb-0">Order items</h4>
                                    </div>
                                </div>
                                <div className="w-100 d-flex justify-content-center" style={{ height: "195px" }}>
                                    <LineChart />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

                {/*============================= Input Search / Export Button =============================*/}
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3">
                        {/*============================= Search Input =============================*/}
                        <div className="input-group input-user-group">
                            <input
                                type="text"
                                value={inputCateringSearch}
                                onChange={(e) => setInputCateringSearch(e.target.value)}
                                onKeyDown={(e) => e.key === "Escape" && setInputCateringSearch("")}
                                className="form-control" placeholder="Search by cost center, meeting room and location"
                            />
                            {inputCateringSearch ? (
                                <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => setInputCateringSearch("")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                    </svg>
                                </span>
                            ) : (
                                <span className="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                    </svg>
                                </span>
                            )}
                        </div>
                    </div>

                    {/*============================= Export Button =============================*/}
                    <div className="col-xl-9 col-lg-0 col-md-0 col-sm-0 text-end">
                        <DownloadTableExcel
                            sheet="Catering"
                            currentTableRef={allCateringRef?.current}
                            filename={`Catering-From-${ApiObjectState?.fromDate}-To-${ApiObjectState?.toDate}`}
                        >
                            <button className="btn btn-outline-primary btn-icon btn-download">
                                <i className="bi bi-download" />
                            </button>
                        </DownloadTableExcel>
                    </div>
                </div>

                <hr />

                {/*============================= Table =============================*/}
                <div className="w-100" >
                    <div className="card catering-table-card mb-0">
                        {/* Table Main Header */}
                        <div className="catering-table-card-header">
                            <div className="d-flex align-items-center flex-wrap flex-fill">
                                <div className="col-date">
                                    <h6 className="title text-ellipsis" title="Date" style={{ paddingLeft: 50 }}>
                                        Date
                                    </h6>
                                </div>
                                <div className="col-order-no">
                                    <h6 className="title text-ellipsis" title="Order No.">
                                        #Order No.
                                    </h6>
                                </div>
                                <div className="col-costcenter">
                                    <h6 className="title text-ellipsis" title="Costcenter">
                                        Costcenter
                                    </h6>
                                </div>
                                <div className="col-company">
                                    <h6 className="title text-ellipsis" title="Company">
                                        Company
                                    </h6>
                                </div>
                                <div className="col-modebooker">
                                    <h6 className="title text-ellipsis" title="Modebooker">
                                        Booked By
                                    </h6>
                                </div>
                                <div className="col-meeting-room">
                                    <h6 className="title text-ellipsis" title="Meeting room/Location">
                                        Meeting room
                                    </h6>
                                </div>
                                <div className="col-orders">
                                    <h6 className="title text-ellipsis" title="Orders">
                                        Orders
                                    </h6>
                                </div>
                                <div className="col-guest">
                                    <h6 className="title text-ellipsis" title="Guest">
                                        Guest
                                    </h6>
                                </div>
                                <div className="col-internal">
                                    <h6 className="title text-ellipsis" title="Costs">

                                    </h6>
                                </div>
                                <div className="col-costs">
                                    <h6 className="title text-ellipsis" title="Costs">
                                        Costs
                                    </h6>
                                </div>
                                <div className="col-total-cost">
                                    <h6 className="title text-ellipsis" title="Total cost">
                                        Total cost
                                    </h6>
                                </div>
                            </div>
                            {/* <div style={{ width: searchedCateringAnalytics?.length > 10 ? 18 : 0 }} /> */}
                        </div>

                        {/* Table Body */}
                        <div className="accordion accordion-registration accordion-first">
                            {searchedCateringAnalytics && searchedCateringAnalytics?.OrdersByDate?.length > 0 ? (
                                searchedCateringAnalytics?.OrdersByDate?.filter((date) => date?.Orders?.length > 0)?.map((date, dateIndex) =>
                                    <div key={dateIndex} className={`accordion-item${accordionIndex[0] === dateIndex ? " open" : ""}`}>
                                        <h2 className="accordion-header">
                                            <div className={`accordion-button py-3${accordionIndex[0] === dateIndex ? "" : " collapsed"}`}
                                                onClick={() => handleFistLevelSelect(dateIndex)}
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#panelsStayOpen-collapse-${dateIndex}`}>
                                                <div className="d-flex align-items-center flex-wrap" style={{ flex: 1 }}>
                                                    <div className="col-date">
                                                        <i className={`bi bi-chevron-${accordionIndex[0] === dateIndex ? "up" : "down"}`} />
                                                        <div className="flex-fill text-ellipsis overflow-hidden">
                                                            <span className="w-100">
                                                                {moment(date?.Date).format("DD-MM-YYYY, ddd")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-order-no"></div>
                                                    <div className="col-costcenter"></div>
                                                    <div className="col-company"></div>
                                                    <div className="col-modebooker"></div>
                                                    <div className="col-meeting-room"></div>
                                                    <div className="col-orders">
                                                        <span>
                                                            {date?.OrdersTotal ?? 0} orders
                                                        </span>
                                                    </div>
                                                    <div className="col-guest">
                                                        <span>
                                                            {date?.GuestsTotal ?? 0}
                                                        </span>
                                                    </div>
                                                    <div className="col-internal"> </div>
                                                    <div className="col-costs"> </div>
                                                    <div className="col-total-cost">
                                                        <span>
                                                            Kr {toLocaleString(date?.CostTotal) ?? 0}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>

                                        <div
                                            id={`panelsStayOpen-collapse-${dateIndex}`}
                                            className={`accordion-collapse ${accordionIndex[0] === dateIndex ? "collapsing" : "collapse"}`}
                                        >
                                            <div className="accordion-body p-0">
                                                <div className="accordion accordion-by-date">
                                                    {date?.Orders?.length > 0 && (
                                                        date?.Orders?.map((order, orderIndex) =>
                                                            <div key={orderIndex} className="accordion-item">
                                                                <h2 className="accordion-header">
                                                                    <div className="accordion-button">
                                                                        <div className="col-date" style={{ paddingLeft: 50 }}>
                                                                            {/* <i className="bi bi-chevron" /> */}
                                                                            <span className="text-ellipsis">
                                                                                {moment(order?.Start).format("HH:mm")} - {moment(order?.End).format("HH:mm")}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-order-no">
                                                                            <span>#{order?.OrderNumber ?? ""}</span>
                                                                        </div>
                                                                        <div className="col-costcenter">
                                                                            <span>{order?.CostCenter?.Name ?? ""}</span>
                                                                        </div>
                                                                        <div className="col-company">
                                                                            <span>{order?.Company ?? ""}</span>
                                                                        </div>
                                                                        <div className="col-modebooker">
                                                                            <span>{order?.BookedBy?.Name ?? ""}</span>
                                                                        </div>
                                                                        <div className="col-meeting-room">
                                                                            <span className="text-wrap">
                                                                                {`${order?.MeetingRoom?.Id ? `${order?.MeetingRoom?.Name ? order?.MeetingRoom?.Name : "--"}, ` : ""}${order?.Location?.Name ?? ""}`}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-orders">
                                                                            {order?.ProductOrders?.length > 0 && (
                                                                                order?.ProductOrders?.map((productOrder, productOrderIndex) =>
                                                                                    <span key={productOrderIndex}>{`${productOrder?.NumberOfItems ?? 0} x ${productOrder?.Product?.Name ?? ""}`}</span>
                                                                                )
                                                                            )}
                                                                            {order?.ProductOtherOrders?.length > 0 && (
                                                                                order?.ProductOtherOrders?.map((otherProductOrder, otherProductOrderIndex) =>
                                                                                    <span key={otherProductOrderIndex}>{`${otherProductOrder?.NumberOfItems ?? 0} x ${otherProductOrder?.Name ?? ""}`}</span>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                        <div className="col-guest">
                                                                            <span>{order?.GuestsInternal ?? 0}</span>
                                                                            <span>{order?.GuestsExternal ?? 0}</span>
                                                                        </div>
                                                                        <div className="col-internal">
                                                                            <span style={{ color: "grey" }}>Internal</span>
                                                                            <span style={{ color: "grey" }}>External</span>
                                                                        </div>
                                                                        <div className="col-costs">
                                                                            <span>Kr {toLocaleString(order?.CostInternal) ?? 0}</span>
                                                                            <span>Kr {toLocaleString(order?.CostExternal) ?? 0}</span>
                                                                        </div>
                                                                        <div className="col-total-cost">
                                                                            <span> Kr {toLocaleString(order?.CostTotal) ?? 0}</span>
                                                                        </div>
                                                                    </div>
                                                                </h2>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="no-record-found w-100 text-center p-3">
                                    <label>No Record Found!</label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="w-100 mt-3 mb-0">
                    <div className="card col-xl-12">
                        <div className="card-body p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="col-xl-6"></div>
                                <div className="caterin-bottom-bar pe-3">
                                    <div className="title">0</div>
                                    <div style={{ color: "var(--primary-50-color)" }}> Total order Items</div>
                                </div>
                                <div className="caterin-bottom-bar pe-3">
                                    <div>{cateringData?.TotalOrders ?? 0}</div>
                                    <div style={{ color: "var(--primary-50-color)" }}>Total order</div>
                                </div>
                                <div className="caterin-bottom-bar pe-3">
                                    <div> {cateringData?.GuestsTotal ?? 0}
                                    </div>
                                    <div style={{ color: "var(--primary-50-color)" }}>Total Guest</div>
                                </div>
                                <div className="caterin-bottom-bar pe-3">
                                    <div>{cateringData?.GuestsInternal ?? 0}
                                    </div>
                                    <div style={{ color: "var(--primary-50-color)" }}>Internal</div>
                                </div>
                                <div className="caterin-bottom-bar pe-3" >
                                    <div className="mr-4">{cateringData?.GuestsExternal ?? 0}
                                    </div>
                                    <div style={{ color: "var(--primary-50-color)" }}>External</div>
                                </div>
                                <div className="caterin-bottom-bar pe-3">
                                    <div>Kr {toLocaleString(cateringData?.CostTotal) ?? 0}</div>
                                    <div style={{ color: "var(--primary-50-color)" }}>Total Cost</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ================================ Don't Touch This! ================================ */}
                {/* =============================== Table To Be Exported ============================== */}
                <table ref={allCateringRef} style={{ display: "none" }}>
                    <tbody>
                        <tr>
                            <th>Date</th>
                            <th>Hours</th>
                            <th>Order No.</th>
                            <th>Costcenter</th>
                            <th>Company</th>
                            <th>Booked By</th>
                            <th>Comment</th>
                            <th>Meeting room</th>
                            <th>Products Orders</th>
                            <th>Total Orders</th>
                            <th>Guest</th>
                            <th>Total Guest</th>
                            <th>Costs</th>
                            <th>Total cost</th>
                        </tr>

                        {searchedCateringAnalytics && searchedCateringAnalytics?.OrdersByDate?.length > 0 && (
                            searchedCateringAnalytics?.OrdersByDate?.map((date, dateIndex) => (
                                date?.Orders && (
                                    date?.Orders?.length > 0 && date?.Orders?.map((order, orderIndex) => (
                                        <tr key={`${dateIndex} ${orderIndex}`}>
                                            <td>{moment(date?.Date).format("DD-MM-YYYY, ddd")}</td>
                                            <td>{moment(order?.Start).format("HH:mm")} - {moment(order?.End).format("HH:mm")}</td>
                                            <td>{order?.OrderNumber}</td>
                                            <td>{order?.CostCenter?.Name}</td>
                                            <td>{order?.Company}</td>
                                            <td>{order?.BookedBy?.Name}</td>
                                            <td>{order?.Comment}</td>
                                            <td>{`${order?.MeetingRoom?.Name}, ${order?.Location?.Name}`}</td>
                                            {(order?.ProductOrders || order?.ProductOtherOrders) && (
                                                <td key={`${orderIndex} ${dateIndex}`}>
                                                    {order?.ProductOrders?.length > 0 && order?.ProductOrders?.map((productOrder, productOrderIndex) => (
                                                        <div key={`${productOrderIndex}`}>
                                                            {`${productOrder?.NumberOfItems} x ${productOrder?.Product?.Name}`}
                                                        </div>
                                                    ))}
                                                    {order?.ProductOtherOrders?.length > 0 && order?.ProductOtherOrders?.map((otherProductOrder, otherProductOrderIndex) => (
                                                        <div key={`${otherProductOrderIndex}`}>
                                                            {`${otherProductOrder?.NumberOfItems} x ${otherProductOrder?.Name}`}
                                                        </div>
                                                    ))}
                                                </td>
                                            )}
                                            <td>{date?.OrdersTotal} orders</td>
                                            <td>Internal {order?.GuestsInternal}, External {order?.GuestsExternal}</td>
                                            <td>{date?.GuestsTotal}</td>
                                            <td>Internal {order?.CostInternal}, External {order?.CostExternal}</td>
                                            <td>{order?.CostTotal}</td>
                                        </tr>
                                    ))
                                )
                            ))
                        )}

                        {/* Total Cost row */}
                        <tr>
                            <td colSpan="11"></td>
                            <td>Total Cost:</td>
                            <td>{cateringData?.CostTotal}</td>
                        </tr>
                    </tbody>
                </table>
            </div >
        </>
    )
}