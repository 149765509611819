import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  CurrentUser: {},
  AppThemeData: {
    AppLogo: { FileName: "", Image: "" },
    Theme: {
      AppThemeColor: "",
      DeskBookingColor: "",
      MyDeskMeetingColor: "",
      TeamsMeetingColor: "",
      WorkFromHomeColor: "",
    },
  },
  ActivePanel: "",
  ApiObject: null,
  EnableMeetingRoom: false,
  EnableDeskBooking: false,
  EnableWorkTime: false,
  EnableCanteen: false,

  AllLocations: [],
  AllFloorPlans: [],
  AllMeetingRooms: [],
  AllAreas: [],
  AllProducts: [],
  AllCompanies: [],
  AllDepartments: [],
  AllCateringDepartments: [],
}

export const APIResponseSlice = createSlice({
  name: "API",
  initialState,
  reducers: {
    changeCurrentUser: (state, action) => {
      typeof action.payload === "function"
        ? state.CurrentUser = action.payload(state.CurrentUser)
        : state.CurrentUser = action.payload
    },
    changeAppThemeData: (state, action) => {
      typeof action.payload === "function"
        ? state.AppThemeData = action.payload(state.AppThemeData)
        : state.AppThemeData = action.payload
    },
    changeActivePanel: (state, action) => {
      typeof action.payload === "function"
        ? state.ActivePanel = action.payload(state.ActivePanel)
        : state.ActivePanel = action.payload
    },
    changeAPIObject: (state, action) => {
      typeof action.payload === "function"
        ? state.ApiObject = action.payload(state.ApiObject)
        : state.ApiObject = action.payload
    },
    changeEnableMeetingRoom: (state, action) => {
      typeof action.payload === "function"
        ? state.EnableMeetingRoom = action.payload(state.EnableMeetingRoom)
        : state.EnableMeetingRoom = action.payload
    },
    changeEnableDeskBooking: (state, action) => {
      typeof action.payload === "function"
        ? state.EnableDeskBooking = action.payload(state.EnableDeskBooking)
        : state.EnableDeskBooking = action.payload
    },
    changeEnableWorkTime: (state, action) => {
      typeof action.payload === "function"
        ? state.EnableWorkTime = action.payload(state.EnableWorkTime)
        : state.EnableWorkTime = action.payload
    },
    changeEnableCanteen: (state, action) => {
      typeof action.payload === "function"
        ? state.EnableCanteen = action.payload(state.EnableCanteen)
        : state.EnableCanteen = action.payload
    },

    changeAllLocations: (state, action) => {
      typeof action.payload === "function"
        ? state.AllLocations = action.payload(state.AllLocations)
        : state.AllLocations = action.payload
    },
    changeAllFloorPlans: (state, action) => {
      typeof action.payload === "function"
        ? state.AllFloorPlans = action.payload(state.AllFloorPlans)
        : state.AllFloorPlans = action.payload
    },
    changeAllMeetingRooms: (state, action) => {
      typeof action.payload === "function"
        ? state.AllMeetingRooms = action.payload(state.AllMeetingRooms)
        : state.AllMeetingRooms = action.payload
    },
    changeAllAreas: (state, action) => {
      typeof action.payload === "function"
        ? state.AllAreas = action.payload(state.AllAreas)
        : state.AllAreas = action.payload
    },
    changeAllProducts: (state, action) => {
      typeof action.payload === "function"
        ? state.AllProducts = action.payload(state.AllProducts)
        : state.AllProducts = action.payload
    },
    changeAllCompanies: (state, action) => {
      typeof action.payload === "function"
        ? state.AllCompanies = action.payload(state.AllCompanies)
        : state.AllCompanies = action.payload
    },
    changeAllDepartments: (state, action) => {
      typeof action.payload === "function"
        ? state.AllDepartments = action.payload(state.AllDepartments)
        : state.AllDepartments = action.payload
    },
    changeAllCateringDepartments: (state, action) => {
      typeof action.payload === "function"
        ? state.AllCateringDepartments = action.payload(state.AllCateringDepartments)
        : state.AllCateringDepartments = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  changeCurrentUser,
  changeAppThemeData,
  changeActivePanel,
  changeAPIObject,
  changeEnableMeetingRoom,
  changeEnableDeskBooking,
  changeEnableWorkTime,
  changeEnableCanteen,

  changeAllLocations,
  changeAllFloorPlans,
  changeAllMeetingRooms,
  changeAllAreas,
  changeAllProducts,
  changeAllCompanies,
  changeAllDepartments,
  changeAllCateringDepartments,
} = APIResponseSlice.actions;

export default APIResponseSlice.reducer
