import React, { useEffect, useMemo, useState } from "react"
import { getAreaMap, getDeskHeatmapData, getFloorPlanMap, getMeetingHeatmapData, getSeats, getSeatsData } from "../../services/ApiServices"
import { useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { handleError } from "../../const"
import { Tooltip } from "react-tooltip"
import moment from "moment"
import "./HeatMap.css"

export default function HeapMap({ Title, renderImage }) {
    const { Theme } = useSelector((state) => state.APIResponse.AppThemeData)
    const filteredFloorPlans = useSelector((state) => state.Filter.FilteredFloorPlans)
    const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)

    const [isLoading, setIsLoading] = useState(false)
    const [isFloorPlanMapLoading, setIsFloorPlanMapLoading] = useState(false)
    const [isAreaMapLoading, setIsAreaMapLoading] = useState(false)

    const [floorPlanMap, setFloorPlanMap] = useState({ Image: "", naturalHeight: 0, naturalWidth: 0 })
    const [areaMap, setAreaMap] = useState({ Image: "", naturalHeight: 0, naturalWidth: 0 })

    const [selectedFloorPlanId, setSelectedFloorPlanId] = useState(filteredFloorPlans[0]?.Id ?? 0)
    const [selectedAreaId, setSelectedAreaId] = useState(0)

    const { FloorplanMeetingRooms, FloorplanAreas } = useMemo(() => (
        (filteredFloorPlans ?? [])?.find((floorPlan) => floorPlan?.Id === selectedFloorPlanId) ?? ({
            FloorplanMeetingRooms: [],
            FloorplanAreas: [],
        })
    ), [selectedFloorPlanId, filteredFloorPlans])
    const selectedArea = useMemo(() => (
        (FloorplanAreas ?? [])?.find((area) => area?.Id === selectedAreaId) ?? ({})
    ), [selectedAreaId, FloorplanAreas])

    const [meetingHeatmapData, setMeetingHeatmapData] = useState([])
    const [deskHeatmapData, setDeskHeatmapData] = useState([])

    const [allSeats, setAllSeats] = useState([])
    const [seatsData, setSeatsData] = useState([])

    const [isAreaMapModalOpen, setIsAreaMapModalOpen] = useState(false)

    const sortedNameFloorPlans = useMemo(() => (
        [...(filteredFloorPlans ?? [])]?.filter((floorPlan) => floorPlan?.Location?.Id)?.map((floorPlan) => ({
            ...floorPlan,
            FullName: `${floorPlan?.Location?.Name ? floorPlan?.Location?.Name : "--"} - ${floorPlan?.Name ? floorPlan?.Name : "--"}`,
        }))?.sort((a, b) => (
            a?.Location?.Name?.localeCompare(b?.Location?.Name)
        ))
    ), [filteredFloorPlans])

    const getColorCodedTooltipClassName = (value, prefixClass) => {
        if (value <= 50) {
            return `${prefixClass}-success`
        } else if (value > 50 && value <= 75) {
            return `${prefixClass}-warning`
        } else if (value > 75) {
            return `${prefixClass}-danger`
        } else {
            return `${prefixClass}-primary`
        }
    }

    const renderMeetingTooltip = (data, i, meetingHeatmapData) => {
        if (data && data?.RoomId) {
            const tooltip = meetingHeatmapData?.length > 0 ? meetingHeatmapData?.find((d) => d?.MeetingRoomId === data?.RoomId) : undefined

            return tooltip && (
                <div
                    key={i} className="tool-tip-wrapper"
                    style={{
                        left: `calc(${(data?.XCoord * 100) / floorPlanMap?.naturalWidth}% - 15px)`,
                        top: `calc(${(data?.YCoord * 100) / floorPlanMap?.naturalHeight}% - 87px)`,
                    }}
                >
                    <div className={`tool-tip ${getColorCodedTooltipClassName(tooltip?.AverageAttendees, "tool-tip")} w-100`}>
                        <div className="d-flex align-items-center">
                            <div className="title">{data?.Name}</div>
                            <span className="usage" style={{ fontSize: "small", lineHeight: "19.5px", fontWeight: 600 }}>
                                {tooltip?.Utilization?.toString().includes(".") ? tooltip?.Utilization?.toString().replace(".", ",") : tooltip?.Utilization}%
                            </span>
                        </div>
                        <div className="tool-tip-details-section">
                            <div className="tool-tip-details">
                                <span className="data-label">Total Meetings</span>
                                <span className="data-value">{tooltip?.MeetingsTotal}</span>
                            </div>
                            <div className="tool-tip-details">
                                <span className="data-label">Average meeting time</span>
                                <span className="data-value">{tooltip?.AverageMeetingTimeMinutes}</span>
                            </div>
                            <div className="tool-tip-details">
                                <span className="data-label">Average Attendees</span>
                                <span className="data-value badge">{tooltip?.AverageAttendees.toString().replace(".", ",")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderDeskTooltip = (data, i, deskHeatmapData) => {
        if (data && data?.Area && data?.Area?.Id) {
            const tooltip = deskHeatmapData?.length > 0 ? deskHeatmapData?.find((d) => d?.AreaId === data?.Area?.Id) : {}
            const utilization = tooltip?.Utilization?.toString().includes(".") ? tooltip?.Utilization?.toString().replace(".", ",") : tooltip?.Utilization

            return (
                <div
                    key={i} className="tool-tip-wrapper"
                    onClick={() => {
                        setIsAreaMapModalOpen(true)
                        setSelectedAreaId(data?.Id)
                    }}
                    style={{
                        left: `calc(${(data?.XCoord * 100) / floorPlanMap?.naturalWidth}% - 15px)`,
                        top: `calc(${(data?.YCoord * 100) / floorPlanMap?.naturalHeight}% - 87px)`,
                    }}
                >
                    <div className={`tool-tip ${getColorCodedTooltipClassName(tooltip?.Utilization, "tool-tip")} w-100`}>
                        <div className="d-flex align-items-center">
                            <div className="title">{data?.Area && data?.Area?.Name}</div>
                            <span className="usage" style={{ fontSize: "small", lineHeight: "19.5px", fontWeight: 600 }}>
                                {utilization}%
                            </span>
                        </div>
                        <div className="tool-tip-details-section">
                            <div className="tool-tip-details">
                                <span className="data-label">Total Seats</span>
                                <span className="data-value">{tooltip?.SeatsTotal}</span>
                            </div>
                            <div className="tool-tip-details">
                                <span className="data-label">Utilization</span>
                                <span className="data-value highlighted">
                                    {utilization}%
                                </span>
                            </div>
                            <div className="tool-tip-details">
                                <span className="data-label">Total Bookings</span>
                                <span className="data-value badge">{tooltip?.SeatsReservedTotalReservations}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderSeat = (data, i, seatsData) => {
        if (seatsData?.length > 0 && data && data?.Id) {
            const seat = seatsData?.find((d) => d?.SeatId === data?.Id)
            const seatName = seat?.SeatId === data?.Id ? data?.Name : ""
            return (
                <div key={i} className={`seats ${getColorCodedTooltipClassName(seat?.Utilization, "seat")}`}
                    style={{
                        left: `calc(${(data?.XCoord * 100) / areaMap?.naturalWidth}% - 20px)`,
                        top: `calc(${(data?.YCoord * 100) / areaMap?.naturalHeight}% - 13px)`,
                    }}
                    data-tooltip-id={`seat-btn-${data?.Id}`}
                    data-tooltip-place="top"
                >
                    <span className="seat-data">
                        {seat?.Utilization?.toString()?.includes(".") ? seat?.Utilization?.toString()?.replace(".", ",") : seat?.Utilization}%
                    </span>

                    <Tooltip id={`seat-btn-${data?.Id}`} style={{ zIndex: "9999" }}>
                        <div>
                            <small>{seatName}</small>
                        </div>
                    </Tooltip>
                </div>
            )
        }
    }

    const onAreaChange = (areaId, direction) => {
        if (FloorplanAreas && FloorplanAreas?.length > 0 && areaId && direction) {
            const areaIndex = FloorplanAreas?.map((d) => d?.Id)?.indexOf(areaId)
            if (direction === "prev") {
                const area = FloorplanAreas[areaIndex - 1] ?? FloorplanAreas[FloorplanAreas?.length - 1]
                setSelectedAreaId(area?.Id)
            } else if (direction === "next") {
                const area = FloorplanAreas[areaIndex + 1] ?? FloorplanAreas[0]
                setSelectedAreaId(area?.Id)
            }
        }
    }

    useEffect(() => {
        if (filteredFloorPlans && filteredFloorPlans?.length > 0) {
            if (selectedFloorPlanId && !filteredFloorPlans?.some((floorPlan) => floorPlan?.Id === selectedFloorPlanId)) {
                setSelectedFloorPlanId(filteredFloorPlans[0]?.Id)
            }
        } else {
            setSelectedFloorPlanId(0)
        }
    }, [filteredFloorPlans, selectedFloorPlanId])

    useEffect(() => {
        if (FloorplanAreas && FloorplanAreas?.length > 0) {
            if (!FloorplanAreas?.some((area) => area?.Id === selectedAreaId)) {
                setSelectedAreaId(FloorplanAreas[0]?.Id)
            }
        } else {
            setSelectedAreaId(0)
            setIsAreaMapModalOpen(false)
        }
    }, [FloorplanAreas, selectedAreaId])

    // Get Seats Data
    useEffect(() => {
        let subscribed = true

        setSeatsData((prev) => prev?.length > 0 ? [] : prev)
        if (selectedAreaId) {
            const requestPayload = {
                FromDate: ApiObjectState?.fromDate,
                ToDate: ApiObjectState?.toDate,
                AreaId: selectedAreaId,
            }

            getSeatsData(requestPayload).then((response) => {
                if (subscribed) {
                    if (response?.Items && typeof response?.Items === "object" && response?.Items?.length >= 0) {
                        setSeatsData(response?.Items)
                    } else {
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    handleError(error)
                }
            })
        }

        return () => {
            subscribed = false
        }
    }, [selectedAreaId, ApiObjectState?.fromDate, ApiObjectState?.toDate])

    // Get All Area Seats
    useEffect(() => {
        let subscribed = true

        setAllSeats((prev) => prev?.length > 0 ? [] : prev)
        if (selectedAreaId) {
            getSeats(selectedAreaId, moment().format("YYYY-MM-DD")).then((response) => {
                if (subscribed) {
                    if (response?.Seats && typeof response?.Seats === "object" && response?.Seats?.length >= 0) {
                        setAllSeats(response?.Seats)
                    } else {
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    handleError(error)
                }
            })
        }

        return () => {
            subscribed = false
        }
    }, [selectedAreaId])

    // Get Are Map
    useEffect(() => {
        let subscribed = true

        setAreaMap({ Image: "", naturalHeight: 0, naturalWidth: 0 })
        if (selectedAreaId) {
            setIsAreaMapLoading(true)
            getAreaMap(selectedAreaId).then((response) => {
                if (subscribed) {
                    if (response?.Image !== undefined) {
                        setAreaMap((prev) => ({ ...prev, Image: response?.Image ?? "" }))
                        // GetSeats(selectedAreaId)
                    } else {
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    handleError(error)
                }
            }).finally(() => {
                if (subscribed) {
                    setIsAreaMapLoading(false)
                }
            })
        }

        return () => {
            subscribed = false
        }
    }, [selectedAreaId])

    // Get FloorPlan Map
    useEffect(() => {
        let subscribed = true

        setFloorPlanMap({ Image: "", naturalHeight: 0, naturalWidth: 0 })
        if (selectedFloorPlanId) {
            setIsFloorPlanMapLoading(true)
            getFloorPlanMap(selectedFloorPlanId).then((response) => {
                if (subscribed) {
                    if (response?.Image !== undefined) {
                        setFloorPlanMap((prev) => ({ ...prev, Image: response?.Image ?? "" }))
                    } else {
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    handleError(error)
                }
            }).finally(() => {
                if (subscribed) {
                    setIsFloorPlanMapLoading(false)
                }
            })
        }

        return () => {
            subscribed = false
        }
    }, [selectedFloorPlanId])

    // =========================== Get Meeting HeatMap Data ===========================
    useEffect(() => {
        let subscribed = true

        if (selectedFloorPlanId && renderImage === "MeetingRoom") {
            setIsLoading(true)
            getMeetingHeatmapData(ApiObjectState?.fromDate, ApiObjectState?.toDate, selectedFloorPlanId, []).then((response) => {
                if (subscribed) {
                    if (response?.Items && typeof response?.Items === "object" && response?.Items?.length >= 0) {
                        setMeetingHeatmapData(response?.Items)
                    } else {
                        setMeetingHeatmapData((prev) => prev?.length > 0 ? [] : prev)
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    setMeetingHeatmapData((prev) => prev?.length > 0 ? [] : prev)
                    handleError(error)
                }
            }).finally(() => {
                if (subscribed) {
                    setIsLoading(false)
                }
            })
        } else {
            setMeetingHeatmapData((prev) => prev?.length > 0 ? [] : prev)
        }

        return () => {
            subscribed = false
        }
    }, [renderImage, ApiObjectState?.fromDate, ApiObjectState?.toDate, selectedFloorPlanId,])

    // =========================== Get Desk HeatMap Data ===========================
    useEffect(() => {
        let subscribed = true

        if (selectedFloorPlanId && renderImage === "DeskSeats") {
            const requestPayload = {
                FromDate: ApiObjectState?.fromDate,
                ToDate: ApiObjectState?.toDate,
                floorplanId: selectedFloorPlanId,
                departments: ApiObjectState?.departments,
            }

            setIsLoading(true)
            getDeskHeatmapData(requestPayload).then((response) => {
                if (subscribed) {
                    if (response?.Items && typeof response?.Items === "object" && response?.Items?.length >= 0) {
                        setDeskHeatmapData(response && response?.Items && response?.Items.length > 0 ? response?.Items : [])
                    } else {
                        setDeskHeatmapData((prev) => prev?.length > 0 ? [] : prev)
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    setDeskHeatmapData((prev) => prev?.length > 0 ? [] : prev)
                    handleError(error)
                }
            }).finally(() => {
                if (subscribed) {
                    setIsLoading(false)
                }
            })
        } else {
            setDeskHeatmapData((prev) => prev?.length > 0 ? [] : prev)
        }

        return () => {
            subscribed = false
        }
    }, [renderImage, selectedFloorPlanId, ApiObjectState?.fromDate, ApiObjectState?.toDate, ApiObjectState?.departments])

    return (
        <>
            <div className="card heat-map m-0">
                <div className="card-body p-4">
                    <div className="row">
                        <div className="col-xl-4 col-md-4 col-sm-12">
                            <h4 className="mb-3">{Title ?? "Heat map"}</h4>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-12">
                            <select
                                value={selectedFloorPlanId}
                                onChange={({ target }) => setSelectedFloorPlanId(Number(target.value))}
                                className="form-select px-2 py-1 mb-3 select-hover"
                                placeholder="Select Option"
                            >
                                <option value={0}>Select Floor Plan</option>
                                {sortedNameFloorPlans && sortedNameFloorPlans?.length > 0 && (
                                    (sortedNameFloorPlans ?? [])?.map((floorPlan, floorPlanIndex) =>
                                        <option key={floorPlanIndex} value={floorPlan?.Id}>
                                            {floorPlan?.FullName}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-12"></div>
                    </div>

                    <div className="main-map-area">
                        {!isFloorPlanMapLoading && floorPlanMap?.Image ? (
                            <>
                                <img
                                    src={floorPlanMap?.Image}
                                    onLoad={(e) => {
                                        setFloorPlanMap((prev) => ({
                                            ...prev,
                                            naturalHeight: e?.target?.naturalHeight,
                                            naturalWidth: e?.target?.naturalWidth,
                                        }))
                                    }}
                                    className="main-map" alt="floor-Plan"
                                />

                                {/* ====================================== Meeting Rooms ====================================== */}
                                {renderImage === "MeetingRoom" && FloorplanMeetingRooms && FloorplanMeetingRooms?.length > 0 && (
                                    FloorplanMeetingRooms?.map((meetingRoom, meetingRoomIndex) =>
                                        renderMeetingTooltip(meetingRoom, meetingRoomIndex, meetingHeatmapData)
                                    )
                                )}
                                {/* -------------------------------------- Meeting Rooms -------------------------------------- */}

                                {/* ====================================== Desk Seats ====================================== */}
                                {renderImage === "DeskSeats" && FloorplanAreas && FloorplanAreas?.length > 0 && (
                                    FloorplanAreas.map((area, areaIndex) =>
                                        renderDeskTooltip(area, areaIndex, deskHeatmapData)
                                    )
                                )}
                                {/* -------------------------------------- Desk Seats -------------------------------------- */}
                            </>
                        ) : isFloorPlanMapLoading ? (
                            <div className="loader" style={{ height: "350px" }}>
                                <ClipLoader color={`${Theme?.AppThemeColor ? "#" + Theme?.AppThemeColor : "#004195"}`} loading={true} size={100} />
                            </div>
                        ) : !floorPlanMap?.Image && (
                            <div className="w-100" style={{ height: "350px" }} />
                        )}
                    </div>
                </div>
            </div>

            {/* ========================== Full Screen Modal ========================== */}
            <div className={`card-modal ${isAreaMapModalOpen ? "open" : ""}`}>
                <div className="modal-header">
                    <button
                        onClick={() => onAreaChange(selectedAreaId, "prev")}
                        className="btn btn-primary px-2 py-1" disabled={isLoading}
                        style={{ backgroundColor: `#${Theme?.AppThemeColor}`, boxShadow: "none", border: "none" }}
                    >
                        <i className="fa-solid fa-arrow-left" />
                    </button>
                    <h3 className="title mx-3 my-0">
                        {selectedArea?.Area?.Name ? selectedArea?.Area?.Name : "--"}
                    </h3>
                    <button
                        onClick={() => onAreaChange(selectedAreaId, "next")}
                        className="btn btn-primary px-2 py-1" disabled={isLoading}
                        style={{ backgroundColor: `#${Theme?.AppThemeColor}`, boxShadow: "none", border: "none" }}
                    >
                        <i className="fa-solid fa-arrow-right" />
                    </button>
                    <i
                        onClick={() => {
                            setIsAreaMapModalOpen(false)
                            setSelectedAreaId(0)
                        }}
                        className="modal-close fa-solid fa-xmark"
                    />
                </div>

                <div className="modal-body">
                    {!isAreaMapLoading && areaMap?.Image ? (
                        <div className="map-area">
                            <img
                                src={areaMap?.Image}
                                onLoad={(e) => setAreaMap({ ...areaMap, naturalHeight: e?.target?.naturalHeight, naturalWidth: e?.target?.naturalWidth })}
                                className="map" alt="floor-Plan"
                            />
                            {areaMap?.Image !== undefined && allSeats && allSeats?.length > 0 && (
                                allSeats?.map((d, i) => renderSeat(d, i, seatsData))
                            )}
                        </div>
                    ) : isAreaMapLoading ? (
                        <div className="map-loader">
                            <ClipLoader color={`${Theme?.AppThemeColor ? "#" + Theme?.AppThemeColor : "#004195"}`} loading={true} size={100} />
                        </div>
                    ) : (
                        <div className="flex-fill" />
                    )}
                </div>
            </div>
        </>
    )
}
