import React, { useEffect, useRef } from "react"
import Chart from "chart.js/auto"

export default function BarChart({ graphToolTipLabel, graphLabel, graphData, graphIds, onBarClick }) {
    const chartInstanceRef = useRef()
    const chartRef = useRef()

    useEffect(() => {
        if (chartRef.current) {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy()
            }

            chartInstanceRef.current = new Chart(chartRef.current, {
                type: "bar",
                data: {
                    labels: graphLabel ? graphLabel : [],
                    datasets: [
                        {
                            label: graphToolTipLabel ? graphToolTipLabel : "",
                            data: graphData ? graphData : [],
                            backgroundColor: ["#147ad6"],
                            borderWidth: 1,
                            borderRadius: 5,
                            fill: true,
                            // barPercentage: 0.5,
                            // barThickness: 15,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: { display: false },
                    },
                    scales: {
                        x: {
                            ticks: {
                                maxRotation: 0, // Keeps labels horizontal
                                minRotation: graphToolTipLabel === "Orders" ? 45 : 0, // Prevents them from rotating
                                callback: function (value, index) {
                                    const data = this.getLabelForValue(value)
                                    return typeof data === "string" && graphToolTipLabel === "Orders" ? this.getLabelForValue(data.substring(0, 7)) + '.' : data
                                }
                            },
                            grid: {
                                display: false,
                            },
                        },
                    },
                    onHover: function (e, elements) {
                        const target = e.native ? e.native.target : e.target
                        target.style.cursor = elements[0] ? 'pointer' : 'default'
                    },
                    onClick: (event, elements) => {
                        if (elements.length > 0 && graphToolTipLabel === "Orders") {
                            const index = elements[0].index
                            const id = graphIds[index]
                            onBarClick && onBarClick(id)
                        }
                    },
                }
            })
        }
    }, [graphToolTipLabel, graphLabel, graphData, graphIds, onBarClick])

    return (
        <canvas ref={chartRef} id="meeting-room-usage" />
    )
}