import { router } from "../utils/APIUrls"
import { base_url } from "../const"
import axioConnectorInstance from "./AxioConnector"
import axios from "axios"

const parseParams = (params) => {
  const keys = typeof params === "object" ? Object.keys(params) : []
  let options = ""

  if (keys?.length > 0) {
    keys?.forEach((key) => {
      const isParamTypeObject = typeof params[key] === "object" && Object.keys(params[key])?.length >= 0
      const isParamTypeArray = isParamTypeObject && params[key]?.length >= 0

      if (isParamTypeArray) {
        params[key]?.forEach((element) => {
          options += `${key}=${element}&`
        })
      } else if (isParamTypeObject) {
        options += parseParams(params[key])
      } else if (!isParamTypeObject && !isParamTypeArray) {
        options += `${key}=${params[key]}&`
      }
    })
  }

  return options ? options.slice(0, -1) : options
}

export const getCurrentUser = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.currentUser, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getTenants = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.tenants, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const switchTenant = async (tenantId) => {
  try {
    const res = await axioConnectorInstance.put(`${router?.switchTenant}/${tenantId}`, "", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getUserImage = async (email) => {
  const queryData = {
    userId: email,
  }
  try {
    const res = await axioConnectorInstance.get(router?.userImage, {
      params: queryData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getAdminLocation = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.locationsWithShared, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getAdminFloorPlans = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.floorPlans, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getAdminDepartments = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.adminDepartments, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getAllDepartments = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.department, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getAllCompanies = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.companies, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getAllProducts = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.products, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}


export const getViolationTypes = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.violationTypes, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getMeetingRooms = async () => {
  try {
    const res = await axioConnectorInstance.get(router?.meetingRoom, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getMeetingAnalytics = async (data) => {
  try {
    const res = await axioConnectorInstance.get(router?.meeting, {
      params: data,
      paramsSerializer: (params) => parseParams(params),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    if (process.env.NODE_ENV === "development") {
      return error.response.data
    }
  }
}

export const getDesksAnalytics = async (data) => {
  try {
    const res = await axioConnectorInstance.get(router?.desks, {
      params: data,
      paramsSerializer: (params) => parseParams(params),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    if (process.env.NODE_ENV === "development") {
      return error.response.data
    }
  }
}

export const getWorkTimeAnalytics = async (data) => {
  try {
    const res = await axioConnectorInstance.get(router?.workTime, {
      params: data,
      paramsSerializer: (params) => parseParams(params),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    if (process.env.NODE_ENV === "development") {
      return error.response.data
    }
  }
}

export const getCateringAnalytics = async (data) => {
  try {
    const res = await axioConnectorInstance.get(router?.catering, {
      params: data,
      paramsSerializer: (params) => parseParams(params),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    if (process.env.NODE_ENV === "development") {
      return error.response.data
    }
  }
}

export const get11HourRule = async (data) => {
  try {
    const res = await axioConnectorInstance.get(router?.elevenHourRule, {
      params: data,
      paramsSerializer: (params) => parseParams(params),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    if (process.env.NODE_ENV === "development") {
      return error.response.data
    }
  }
}

export const get48Hours = async (data) => {
  try {
    const res = await axioConnectorInstance.get(router?.fortyEightHours, {
      params: data,
      paramsSerializer: (params) => parseParams(params),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    if (process.env.NODE_ENV === "development") {
      return error.response.data
    }
  }
}

export const getFloorPlanMap = async (floorplanId) => {
  try {
    const res = await axios.get(`${base_url}/Floorplans/${floorplanId}/Map`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    return error.response.data
  }
}

export const getAreaMap = async (areaId) => {
  try {
    const res = await axios.get(`${base_url}/Areas/${areaId}/Map`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    return error.response.data
  }
}

export const getSeats = async (areaId, date) => {
  try {
    const res = await axios.get(`${base_url}/Areas/${areaId}/Seats?date=${date}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    return error.response.data
  }
}

export const getMeetingHeatmapData = async (fromDate, toDate, floorplanId) => {
  const queryData = {
    FromDate: fromDate,
    ToDate: toDate,
    floorplanId: floorplanId,
  }

  try {
    const res = await axioConnectorInstance.get(router?.meetingHeatmap, {
      params: queryData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    return error.response.data
  }
}

export const getDeskHeatmapData = async (requestPayload) => {
  try {
    const res = await axioConnectorInstance.get(router?.deskHeatmap, {
      params: requestPayload,
      paramsSerializer: (params) => parseParams(params),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    return error.response.data
  }
}

export const getSeatsData = async (requestPayload) => {
  try {
    const res = await axioConnectorInstance.get(router?.area, {
      params: requestPayload,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    return error.response.data
  }
}

export const changeStatus = async (data) => {
  try {
    const res = await axioConnectorInstance.put(router?.status, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    return error.response.data
  }
}

export const sendReminder = async (data) => {
  try {
    const res = await axioConnectorInstance.post(router?.reminder, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.warn(error)
    return error.response.data
  }
}