import React, { useCallback, useEffect } from "react"
import { changeSelectedCateringDepartments } from "../../../store/slices/FilterSlice"
import { useDispatch, useSelector } from "react-redux"
import Chart from "chart.js/auto"
import "chartjs-plugin-datalabels"

const CateringDonutChart = ({ graphData, backgroundColors }) => {
    const selectedCateringDepartments = useSelector((state) => state.Filter.SelectedCateringDepartments)

    const dispatch = useDispatch()

    const handleDonutClick = useCallback((costCenter) => {
        if (costCenter) {
            let newSelectedCostCenter = []
            if (!selectedCateringDepartments.includes(costCenter)) {
                newSelectedCostCenter = [...selectedCateringDepartments, costCenter]
            } else if (selectedCateringDepartments.includes(costCenter)) {
                newSelectedCostCenter = selectedCateringDepartments.filter((d) => d !== costCenter)
            }
            dispatch(changeSelectedCateringDepartments(newSelectedCostCenter))
        }

    }, [dispatch, selectedCateringDepartments])

    useEffect(() => {
        const labels = []
        const dataValues = []

        if (graphData?.CostByCostCenter && graphData?.CostByCostCenter?.length > 0) {
            graphData?.CostByCostCenter?.forEach((item) => {
                labels.push(item.CostCenter.Name)
                dataValues.push(item.CostTotal)
            })
        }

        const ctx = document.getElementById("donutChart")

        // Create the donut chart
        const donutChart = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: labels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: backgroundColors,
                    }
                ]
            },
            weight: 1,
            borderRadius: 4,
            options: {
                responsive: true,
                layout: {
                    padding: 4
                },
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                onHover: function (e, elements) {
                    const target = e.native ? e.native.target : e.target
                    target.style.cursor = elements[0] ? 'pointer' : 'default'
                },
                onClick: (event, elements) => {
                    if (elements.length > 0) {
                        const index = elements[0]?.index
                        const id = graphData?.CostByCostCenter[index]?.CostCenter?.Id
                        handleDonutClick(id)
                    }
                },
            },
        })

        // Clean up
        return () => {
            donutChart.destroy()
        }
    }, [backgroundColors, graphData, handleDonutClick])

    return <canvas id="donutChart" />
}

export default CateringDonutChart
