import toast from "react-hot-toast"

export const base_url = "https://rest.mydesk-test.dk/api" //Test
export const clientId = "dfc7a5bf-7579-4edc-8a76-905bec3ed840" //Test
export const scope = "https://portlr.onmicrosoft.com/test/user_impersonation" //Test

// export const base_url = "https://rest.mydesk.dk/api" //Prod
// export const clientId = "ac2195a2-2960-452d-9cb9-ad6e9dfa9e16" //Prod
// export const scope = "https://portlr.onmicrosoft.com/user_impersonation" //Prod

export const appVersion = "v2.2.3.0"
export const accessRoles = ["Administrator", "Dashboard"]

export const sortResponseByKey = (response, key) => {
    if (response && typeof response === "object" && response?.length > 0 && key) {
        const tempResponse = [...response]
        const sortedArray = tempResponse?.sort((a, b) => (a[key] || "")?.toString().localeCompare((b[key] || "")?.toString()))
        return sortedArray
    } else {
        return []
    }
}

export const getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const handleError = (error) => {
    console.error("Error:", error)
    if (typeof error === "string") {
        toast.error(error)
    } else if (error?.ExceptionMessage || error?.Message) {
        toast.error(error?.ExceptionMessage || error?.Message)
    }
}