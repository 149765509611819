import React, { useEffect, useMemo, useRef, useState } from "react"
import { changeStatus, get11HourRule, get48Hours, getViolationTypes, getWorkTimeAnalytics, sendReminder } from "../../services/ApiServices"
import { DownloadTableExcel } from "react-export-table-to-excel"
import { confirmAlert } from "react-confirm-alert"
import { handleError, sortResponseByKey } from "../../const"
import { useSelector } from "react-redux"
import Loader from "../Loader/Loader"
import toast from "react-hot-toast"
import Select from "react-select"
import moment from "moment"
import "react-confirm-alert/src/react-confirm-alert.css"
import "./WorkTimeNewDesign.css"

export default function WorkTimeNewDesign() {
    const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)
    const allDepartments = useSelector((state) => state.APIResponse.AllDepartments)

    const allRegRef = useRef()
    const rest11hrsRef = useRef()
    const rule48hrsRef = useRef()

    const tableTypesArray = [
        { Id: 0, Type: "All Registration" },
        { Id: 1, Type: "Violation - Rest ( 11 hrs. )" },
        { Id: 2, Type: "Violation - Rule ( 48 hrs. )" },
    ]

    const [isScrollable, setIsScrollable] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [isViolationTypesLoading, setIsViolationTypesLoading] = useState(false)
    const [allViolationTypes, setAllViolationTypes] = useState([])

    const [workTimeAnalytics, setWorkTimeAnalytics] = useState({})
    const [inputWorkTimeSearch, setInputWorkTimeSearch] = useState("")
    const searchedWorkTimeAnalytics = useMemo(() => {
        if (workTimeAnalytics?.Users && workTimeAnalytics?.Users?.length > 0) {
            return workTimeAnalytics?.Users?.filter((user) =>
                user?.DisplayName?.toLowerCase()?.includes(inputWorkTimeSearch?.toLowerCase()) ||
                user?.Username?.toLowerCase()?.includes(inputWorkTimeSearch?.toLowerCase())
            )
        } else {
            return []
        }
    }, [inputWorkTimeSearch, workTimeAnalytics?.Users])

    const [elevenHourRuleAnalytics, setElevenHourRuleAnalytics] = useState({ Users: [] })
    const [inputElevenHourRuleSearch, setInputElevenHourRuleSearch] = useState("")
    const searchedElevenHourRuleAnalytics = useMemo(() => {
        if (elevenHourRuleAnalytics?.Users && elevenHourRuleAnalytics?.Users?.length > 0) {
            return elevenHourRuleAnalytics?.Users?.filter((user) =>
                user?.DisplayName?.toLowerCase()?.includes(inputElevenHourRuleSearch?.toLowerCase()) ||
                user?.Username?.toLowerCase()?.includes(inputElevenHourRuleSearch?.toLowerCase())
            )
        } else {
            return []
        }
    }, [inputElevenHourRuleSearch, elevenHourRuleAnalytics?.Users])

    const [fortyEightHoursAnalytics, setFortyEightyHoursAnalytics] = useState({ Users: [] })
    const [inputFortyEightHoursSearch, setInputFortyEightHoursSearch] = useState("")
    const searchedFortyEightHoursAnalytics = useMemo(() => {
        if (fortyEightHoursAnalytics?.Users && fortyEightHoursAnalytics?.Users?.length > 0) {
            return fortyEightHoursAnalytics?.Users?.filter((user) =>
                user?.DisplayName?.toLowerCase()?.includes(inputFortyEightHoursSearch?.toLowerCase()) ||
                user?.Username?.toLowerCase()?.includes(inputFortyEightHoursSearch?.toLowerCase())
            )
        } else {
            return []
        }
    }, [inputFortyEightHoursSearch, fortyEightHoursAnalytics?.Users])

    const [activeTable, setActiveTable] = useState(tableTypesArray[0]?.Id) // "All Registration" || Violation - Rest ( 11 hrs. ) || Violation - Rule ( 48 hrs. )
    const [selectedRegistrationType, setSelectedRegistrationType] = useState(0)
    const [selectedViolationType, setSelectedViolationType] = useState(0)
    const [selectedStatusType, setSelectedStatusType] = useState(-1)
    const [selectedDepartment, setSelectedDepartment] = useState("")

    const [accordionIndex, setAccordionIndex] = useState([null, null, null])

    const scrollRef = useRef()

    const workSummary = useMemo(() => {
        if (
            workTimeAnalytics?.RegistrationTypeSummary &&
            workTimeAnalytics?.RegistrationTypeSummary?.RegistrationTypeSummaryByType &&
            workTimeAnalytics?.RegistrationTypeSummary?.RegistrationTypeSummaryByType?.length > 0
        ) {
            return workTimeAnalytics?.RegistrationTypeSummary?.RegistrationTypeSummaryByType?.filter((summary) =>
                !summary?.RegistrationType?.IsAbsence
            )
        } else {
            return []
        }
    }, [workTimeAnalytics?.RegistrationTypeSummary])

    const absenceSummary = useMemo(() => {
        if (
            workTimeAnalytics?.RegistrationTypeSummary &&
            workTimeAnalytics?.RegistrationTypeSummary?.RegistrationTypeSummaryByType &&
            workTimeAnalytics?.RegistrationTypeSummary?.RegistrationTypeSummaryByType?.length > 0
        ) {
            return workTimeAnalytics?.RegistrationTypeSummary?.RegistrationTypeSummaryByType?.filter((summary) =>
                summary?.RegistrationType?.IsAbsence
            )
        } else {
            return []
        }
    }, [workTimeAnalytics?.RegistrationTypeSummary])

    const violationSummary = useMemo(() => {
        if (
            workTimeAnalytics?.ViolationSummary &&
            workTimeAnalytics?.ViolationSummary?.ViolationsSummeryByType &&
            workTimeAnalytics?.ViolationSummary?.ViolationsSummeryByType?.length > 0
        ) {
            return workTimeAnalytics?.ViolationSummary?.ViolationsSummeryByType
        } else {
            return []
        }
    }, [workTimeAnalytics?.ViolationSummary])

    const handleScroll = (direction) => {
        if (scrollRef?.current && scrollRef?.current?.scrollTo) {
            if (direction === "previous") {
                scrollRef?.current?.scrollTo({ left: scrollRef?.current?.scrollLeft - 180, behavior: "smooth" })
            } else if (direction === "next") {
                scrollRef?.current?.scrollTo({ left: scrollRef?.current?.scrollLeft + 180, behavior: "smooth" })
            }
        }
    }

    const handleFistLevelSelect = (firstLevelIndex) => {
        setAccordionIndex((prev) => [prev[0] !== firstLevelIndex ? firstLevelIndex : null, null, null])
    }

    const handleSecondLevelSelect = (firstLevelIndex, secondLevelIndex) => {
        setAccordionIndex((prev) => [firstLevelIndex, prev[1] !== secondLevelIndex ? secondLevelIndex : null, null])
    }

    const handleThirdLevelSelect = (firstLevelIndex, secondLevelIndex, ThirdLevelIndex) => {
        setAccordionIndex((prev) => [firstLevelIndex, secondLevelIndex, prev[2] !== ThirdLevelIndex ? ThirdLevelIndex : null])
    }

    const calculateTimeDiff = (start, end) => {
        const StartTime = moment(start, "HH:mm")
        const EndTime = moment(end, "HH:mm")

        const diff = `${moment.duration(EndTime.diff(StartTime)).asHours()?.toFixed(2)}`

        return diff?.replace(".", ",")
    }

    const ChangeStatus = async (userName, registrations, status) => {
        const submittedUser = (searchedWorkTimeAnalytics ?? []).filter((user) => user?.HoursWork > 0 && user?.Username === userName)
        const pendingCheckout = submittedUser
            ?.flatMap((user) => user?.RegistrationsByDate)
            ?.find((date) =>
                date?.Registrations?.some((d) =>
                    d?.StartHour !== null && d?.StartMinute !== null && d?.EndHour === null && d?.EndMinute === null
                )
            ) || null

        if (registrations?.length > 1 && registrations?.some((d) =>
            d?.Registrations?.some((r) =>
                r?.StartHour !== null &&
                r?.StartMinute !== null &&
                r?.EndHour === null &&
                r?.EndMinute === null
            )
        )) {
            toast.error(`Checkout is pending for ${moment(pendingCheckout?.Date).format("YYYY-MM-DD")}`)
        } else if (registrations?.length === 1 && !registrations[0]?.IsSubmitted && registrations?.some((d) =>
            d?.Registrations?.some((r) =>
                r?.StartHour !== null &&
                r?.StartMinute !== null &&
                r?.EndHour === null &&
                r?.EndMinute === null
            )
        )) {
            toast.error("Checkout is pending")
        } else {
            let allResponse = []

            setIsLoading(true)
            for (let index = 0; index < registrations?.length; index++) {
                const registration = registrations[index];

                const payloadData = {
                    "DateFrom": registration?.Date,
                    "DateTo": registration?.Date,
                    "Username": userName,
                    "Submit": typeof status === "boolean" ? status : !registration?.IsSubmitted
                }

                if ((registrations?.length > 1 && typeof status === "boolean" && status && !registration?.IsSubmitted) || registrations?.length === 1) {
                    await changeStatus(payloadData).then((response) => {
                        if (!response) {
                            allResponse?.push(true)
                            setWorkTimeAnalytics((prev) => ({
                                ...prev,
                                Users: prev?.Users?.map((user) => ({
                                    ...user,
                                    RegistrationsByDate: user?.Username === userName
                                        ? user?.RegistrationsByDate?.map((byDate) => ({
                                            ...byDate,
                                            IsSubmitted: byDate?.Date === registration?.Date
                                                ? typeof status === "boolean" ? status : !registration?.IsSubmitted
                                                : byDate?.IsSubmitted
                                        }))
                                        : user?.RegistrationsByDate
                                }))
                            }))
                        } else {
                            allResponse?.push(response)
                            if (typeof response === "string") {
                                toast.error(response)

                            } else if (response?.ExceptionMessage || response?.Message) {
                                toast.error(response?.ExceptionMessage || response?.Message)
                            }
                        }
                    }).catch((error) => {
                        console.warn(error)
                    })
                }
            }

            if (registrations?.length > 1) {
                if (!allResponse?.some((response) => response !== true)) {
                    toast.success("Submitted all successfully.")
                } else {
                    toast.error("Error!")
                }
            } else {
                if (allResponse[0] === true) {
                    toast.success("Status changed successfully.")
                } else {
                    if (typeof allResponse[0] === "string") {
                        toast.error(allResponse[0])

                    } else if (allResponse[0]?.ExceptionMessage || allResponse[0]?.Message) {
                        toast.error(allResponse[0]?.ExceptionMessage || allResponse[0]?.Message)
                    }
                }
            }
            setIsLoading(false)
        }

        return
    }

    const SendReminder = async (userName) => {
        const payloadData = {
            "Username": userName
        }

        setIsLoading(true)
        await sendReminder(payloadData).then((response) => {
            if (!response) {
                toast.success("Reminder sent")
            } else {
                toast.error("Error while sending reminder")
            }
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        setAccordionIndex([null, null, null])
    }, [activeTable])

    // Get 11HoursRule And 48Hours Analytics Data
    useEffect(() => {
        let subscribed = true;

        if (typeof ApiObjectState === "object" && ApiObjectState?.fromDate && ApiObjectState?.toDate) {
            let data = {
                fromDate: ApiObjectState?.fromDate,
                toDate: ApiObjectState?.toDate,
            }

            if (activeTable === 1) {
                setIsLoading(true)
                get11HourRule(data).then((response) => {
                    if (subscribed) {
                        // 11 Hour Rule
                        if (typeof response === "object" && response !== null && Object.keys(response)?.length > 0) {
                            setElevenHourRuleAnalytics({ ...response, Users: sortResponseByKey(response?.Users, "DisplayName") })
                        } else {
                            if (typeof response === "string") {
                                toast.error(response)
                            } else if (response?.ExceptionMessage || response?.Message) {
                                toast.error(response?.ExceptionMessage || response?.Message)
                            }
                        }
                    }
                }).catch((error) => {
                    if (subscribed) {
                        console.warn(error)
                    }
                }).finally(() => {
                    if (subscribed) {
                        setIsLoading(false)
                    }
                })
            } else if (activeTable === 2) {
                setIsLoading(true)
                get48Hours(data).then((response) => {
                    if (subscribed) {
                        // 48 Hours
                        if (typeof response === "object" && response !== null && Object.keys(response)?.length > 0) {
                            setFortyEightyHoursAnalytics({ ...response, Users: sortResponseByKey(response?.Users, "DisplayName") })
                        } else {
                            if (typeof response === "string") {
                                toast.error(response)
                            } else if (response?.ExceptionMessage || response?.Message) {
                                toast.error(response?.ExceptionMessage || response?.Message)
                            }
                        }
                    }
                }).catch((error) => {
                    if (subscribed) {
                        console.warn(error)
                    }
                }).finally(() => {
                    if (subscribed) {
                        setIsLoading(false)
                    }
                })
            }
        }

        return () => {
            subscribed = false;
        }
    }, [ApiObjectState, activeTable])

    // Get WorkTime Analytics Data
    useEffect(() => {
        let subscribed = true

        if (ApiObjectState?.fromDate && ApiObjectState?.toDate && activeTable === 0) {
            let data = {
                fromDate: ApiObjectState?.fromDate,
                toDate: ApiObjectState?.toDate,
            }

            if (ApiObjectState?.locationIds) {
                data = { ...data, locationIds: ApiObjectState?.locationIds }
            }
            // if (ApiObjectState?.departments) {
            //     data = { ...data, departments: ApiObjectState?.departments }
            // }
            if (ApiObjectState?.managers) {
                data = { ...data, managers: ApiObjectState?.managers }
            }
            if (selectedRegistrationType) {
                data = { ...data, registrationTypeId: selectedRegistrationType }
            }
            if (selectedViolationType) {
                data = { ...data, violationTypeId: selectedViolationType }
            }
            if (selectedStatusType > -1) {
                data = { ...data, submittedState: selectedStatusType }
            }
            if (selectedDepartment?.length > 0) {
                data = { ...data, departments: selectedDepartment?.map((d) => d?.value) }
            }

            setIsLoading(true)
            getWorkTimeAnalytics(data).then((response) => {
                if (subscribed) {
                    // All Registration
                    if (typeof response === "object" && response && Object.keys(response)?.length >= 0 && !response?.ExceptionMessage && !response?.Message) {
                        setWorkTimeAnalytics({ ...response, Users: sortResponseByKey(response?.Users, "DisplayName") })
                    } else {
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    console.warn(error)
                }
            }).finally(() => {
                if (subscribed) {
                    setIsLoading(false)
                }
            })
        }

        return () => { subscribed = false }
    }, [
        ApiObjectState?.fromDate,
        ApiObjectState?.toDate,
        ApiObjectState?.locationIds,
        // ApiObjectState?.departments,
        ApiObjectState?.managers,
        selectedRegistrationType,
        selectedViolationType,
        selectedStatusType,
        activeTable,
        selectedDepartment,
    ])

    // Get All Violation Types
    useEffect(() => {
        setIsViolationTypesLoading(true)
        getViolationTypes().then((response) => {
            if (typeof response === "object" && response?.length > 0) {
                setAllViolationTypes(response)
            } else if (typeof response === "string") {
                toast.error(response)
            } else if (response?.ExceptionMessage || response?.Message) {
                toast.error(response?.ExceptionMessage || response?.Message)
            }
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            setIsViolationTypesLoading(false)
        })
    }, [])

    useEffect(() => {
        const checkScroll = () => {
            if (scrollRef.current) {
                setIsScrollable(scrollRef.current.scrollWidth > scrollRef.current.clientWidth);
            }
        };

        // Check initially
        checkScroll();

        // Check whenever the window resizes
        window.addEventListener('resize', checkScroll);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', checkScroll);
        };
    }, [violationSummary, absenceSummary]);

    return (
        <>
            {(isLoading || isViolationTypesLoading) && <Loader />}

            <div className="tab-pane work-time-tab">
                {/* ==================== Top Cards And Buttons ==================== */}
                <div className="w-100 d-flex align-items-center mb-4 gap-3">
                    {isScrollable && (
                        <button onClick={() => handleScroll("previous")} className="btn btn-navigate">
                            <span style={{ fontSize: 32 }}>{"<"}</span>
                        </button>
                    )}

                    {/* Horizontal Scroll */}
                    <div ref={scrollRef} className="scroll-view">
                        <div className="card filter-card filter-card-primary shadownone">
                            <label className="title">
                                Submitted
                            </label>
                            <span className="digit-lg">
                                {workTimeAnalytics?.RegistrationsSubmitted ? workTimeAnalytics?.RegistrationsSubmitted : 0}
                            </span>
                            <span className="digit-sm">&nbsp;</span>
                        </div>

                        <div className="card filter-card filter-card-primary shadownone">
                            <label className="title">
                                Pending
                            </label>
                            <span className="digit-lg">
                                {workTimeAnalytics?.RegistrationsNotSubmitted ? workTimeAnalytics?.RegistrationsNotSubmitted : 0}
                            </span>
                            <span className="digit-sm">&nbsp;</span>
                        </div>

                        {/* {workSummary?.length > 0 && (
                            workSummary?.map((summary, summaryIndex) =>
                                <div key={summaryIndex} className="card filter-card filter-card-primary shadownone">
                                    <label className="title" title={summary?.RegistrationType?.Text ? summary?.RegistrationType?.Text : "--"}>
                                        {summary?.RegistrationType?.Text ? summary?.RegistrationType?.Text : "--"}
                                    </label>
                                    <span className="digit-lg">
                                        {summary?.TotalCount ? summary?.TotalCount : 0}
                                    </span>
                                    <span className="digit-sm">
                                        {`${(summary?.TotalSubCount ? summary?.TotalSubCount : 0)?.toFixed(2)}`?.replace(".", ",")}
                                    </span>
                                </div>
                            )
                        )} */}

                        {violationSummary?.length > 0 && (
                            violationSummary?.map((summary, summaryIndex) =>
                                <div key={summaryIndex} className="card filter-card filter-card-danger shadownone">
                                    <label className="title" title={summary?.ViolationType?.Name ? summary?.ViolationType?.Name : "--"}>
                                        {summary?.ViolationType?.Name ? summary?.ViolationType?.Name : "--"}
                                    </label>
                                    <span className="digit-lg">
                                        {summary?.TotalCount ? summary?.TotalCount : 0}
                                    </span>
                                    <span className="digit-sm">&nbsp;</span>
                                </div>
                            )
                        )}

                        <div className="card filter-card filter-card-warning shadownone">
                            <label className="title">
                                Out of office
                            </label>
                            <span className="digit-lg">
                                {workTimeAnalytics?.RegistrationTypeSummary && workTimeAnalytics?.RegistrationTypeSummary?.TotalAbsenceCount
                                    ? workTimeAnalytics?.RegistrationTypeSummary?.TotalAbsenceCount
                                    : 0
                                }
                            </span>
                            <span className="digit-sm">&nbsp;</span>
                        </div>

                        {absenceSummary?.length > 0 && (
                            absenceSummary?.map((summary, summaryIndex) =>
                                <div key={summaryIndex} className="card filter-card filter-card-warning shadownone">
                                    <label className="title">
                                        {summary?.RegistrationType?.Text ? summary?.RegistrationType?.Text : "--"}
                                    </label>
                                    <span className="digit-lg">
                                        {summary?.TotalCount ? summary?.TotalCount : 0}
                                    </span>
                                    <span className="digit-sm">
                                        {summary?.TotalSubCount ? summary?.TotalSubCount : 0}
                                    </span>
                                </div>
                            )
                        )}
                    </div>

                    {isScrollable && (
                        <button onClick={() => handleScroll("next")} className="btn btn-navigate">
                            <span style={{ fontSize: 32 }}>{">"}</span>
                        </button>
                    )}
                </div>

                {/* ==================== Info Table ==================== */}
                <div className="d-flex flex-column w-100 justify-content-end align-items-end">
                    <div className="extra-width-row">
                        {/* ==================== Tables Tab ==================== */}
                        <div className="row">
                            <div className="col-xl-6 col-lg-9 col-md-12 col-sm-12 mb-3">
                                <div className="table-toggle">
                                    {tableTypesArray?.length > 0 && (
                                        tableTypesArray?.map((table, tableIndex) =>
                                            <button
                                                onClick={() => setActiveTable(table?.Id)}
                                                key={tableIndex}
                                                className={`btn switch-table-btn${activeTable === table?.Id ? " active" : ""}`}
                                                title={table?.Type}
                                            >
                                                {table?.Type}
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>

                        <hr />

                        {/* ==================== Search And Export ==================== */}
                        <div className="row">
                            <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12 mb-3">
                                {/* Search Input */}
                                {activeTable === 0 ? (
                                    <div className="input-group input-user-group">
                                        <input
                                            type="text" value={inputWorkTimeSearch}
                                            onChange={(e) => setInputWorkTimeSearch(e.target.value)}
                                            onKeyDown={(e) => e.key === "Escape" && setInputWorkTimeSearch("")}
                                            className="form-control" placeholder="Search by Name or Email"
                                        />
                                        {inputWorkTimeSearch ? (
                                            <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => setInputWorkTimeSearch("")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                                </svg>
                                            </span>
                                        ) : (
                                            <span className="input-group-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                                </svg>
                                            </span>
                                        )}
                                    </div>
                                ) : activeTable === 1 ? (
                                    <div className="input-group input-user-group">
                                        <input
                                            type="text" value={inputElevenHourRuleSearch}
                                            onChange={(e) => setInputElevenHourRuleSearch(e.target.value)}
                                            onKeyDown={(e) => e.key === "Escape" && setInputElevenHourRuleSearch("")}
                                            className="form-control" placeholder="Search by Name or Email"
                                        />
                                        {inputElevenHourRuleSearch ? (
                                            <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => setInputElevenHourRuleSearch("")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                                </svg>
                                            </span>
                                        ) : (
                                            <span className="input-group-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                                </svg>
                                            </span>
                                        )}
                                    </div>
                                ) : activeTable === 2 && (
                                    <div className="input-group input-user-group">
                                        <input
                                            type="text" value={inputFortyEightHoursSearch}
                                            onChange={(e) => setInputFortyEightHoursSearch(e.target.value)}
                                            onKeyDown={(e) => e.key === "Escape" && setInputFortyEightHoursSearch("")}
                                            className="form-control" placeholder="Search by Name or Email"
                                        />
                                        {inputFortyEightHoursSearch ? (
                                            <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => setInputFortyEightHoursSearch("")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                                </svg>
                                            </span>
                                        ) : (
                                            <span className="input-group-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                                </svg>
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>

                            {activeTable === 0 ? (
                                <>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                                        <select
                                            value={selectedRegistrationType}
                                            onChange={(e) => setSelectedRegistrationType(Number(e.target.value))}
                                            className="form-select mb-3"
                                        >
                                            <option value={0}>Select Registration Type</option>
                                            {[...(workSummary?.length > 0 ? workSummary : []), ...(absenceSummary?.length > 0 ? absenceSummary : [])]?.map((type, typeIndex) =>
                                                <option key={typeIndex} value={type?.RegistrationType?.Id}>
                                                    {type?.RegistrationType?.Text ? type?.RegistrationType?.Text : "--"}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                                        <select
                                            value={selectedViolationType}
                                            onChange={(e) => setSelectedViolationType(Number(e.target.value))}
                                            className="form-select mb-3"
                                        >
                                            <option value={0}>Select Violation Type</option>
                                            {allViolationTypes && allViolationTypes?.length > 0 && (
                                                allViolationTypes?.map((type, typeIndex) =>
                                                    <option key={typeIndex} value={type?.Id}>
                                                        {type?.Name ? type?.Name : "--"}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                                        <select
                                            value={selectedStatusType}
                                            onChange={(e) => setSelectedStatusType(Number(e.target.value))}
                                            className="form-select mb-3"
                                        >
                                            <option value={-1}>Select Registration Status</option>
                                            <option value={0}>Pending</option>
                                            <option value={1}>Submitted</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-3 col-lg-9 col-md-8 col-sm-12">
                                        <Select
                                            isMulti
                                            className="px-0 py-0 mb-3"
                                            placeholder="Select Department"
                                            value={selectedDepartment}
                                            onChange={(e) => setSelectedDepartment(e)}
                                            options={allDepartments?.length > 0 && allDepartments?.map((department) => ({ label: department, value: department }))}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    border: "2px solid var(--app-theme-50-color)",
                                                    borderRadius: "8px",
                                                    outline: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                                DropdownIndicator: () => null
                                            }}
                                        />
                                    </div>
                                    <div className="col-xl-1 col-lg-3 col-md-4 col-sm-12 text-end">
                                        {/* Export Button */}
                                        <DownloadTableExcel
                                            sheet="WorkTime"
                                            currentTableRef={allRegRef?.current}
                                            filename={`Registrations-From-${ApiObjectState?.fromDate}-To-${ApiObjectState?.toDate}`}
                                        >
                                            <button className="btn btn-outline-primary btn-icon btn-download mb-3">
                                                <i className="bi bi-download" />
                                            </button>
                                        </DownloadTableExcel>
                                    </div>
                                </>
                            ) : activeTable === 1 ? (
                                <div className="col-xl-10 col-lg-9 col-md-0 col-sm-0 mb-3 text-end">
                                    {/* Export Button */}
                                    <DownloadTableExcel
                                        sheet="WorkTime"
                                        currentTableRef={rest11hrsRef?.current}
                                        filename={`Violation-Rest-11Hrs-From-${ApiObjectState?.fromDate}-To-${ApiObjectState?.toDate}`}
                                    >
                                        <button className="btn btn-outline-primary btn-icon btn-download">
                                            <i className="bi bi-download" />
                                        </button>
                                    </DownloadTableExcel>
                                </div>
                            ) : activeTable === 2 && (
                                <div className="col-xl-10 col-lg-9 col-md-0 col-sm-0 mb-3 text-end">
                                    {/* Export Button */}
                                    <DownloadTableExcel
                                        sheet="WorkTime"
                                        currentTableRef={rule48hrsRef?.current}
                                        filename={`Violation-Rule-48Hrs-From-${ApiObjectState?.fromDate}-To-${ApiObjectState?.toDate}`}
                                    >
                                        <button className="btn btn-outline-primary btn-icon btn-download">
                                            <i className="bi bi-download" />
                                        </button>
                                    </DownloadTableExcel>
                                </div>
                            )}
                        </div>

                        <hr />

                        {/* ==================== Table ==================== */}
                        <div className="w-100">
                            {activeTable === 0 && (
                                <div className="card work-time-table-card mb-0">
                                    {/* Table Main Header */}
                                    <div className="work-time-table-card-header">
                                        <div className="d-flex align-items-center flex-wrap flex-fill">
                                            <div className="col-employee">
                                                <h6 className="title text-ellipsis" title="Employee" style={{ paddingLeft: 50 }}>
                                                    Employee
                                                </h6>
                                            </div>
                                            <div className="col-total-hrs">
                                                <h6 className="title text-ellipsis" title="Total hrs.">
                                                    Total hrs.
                                                </h6>
                                            </div>
                                            <div className="col-rest-time">
                                                <h6 className="title text-ellipsis" title="Rest time (at least 10.75hrs.)">
                                                    Rest time (at least 10.75hrs.)
                                                </h6>
                                            </div>
                                            <div className="col-working-hrs">
                                                <h6 className="title text-ellipsis" title="Working hours. (Avg.)">
                                                    Working hours. (Avg.)
                                                </h6>
                                            </div>
                                            <div className="col-out-of-office">
                                                <h6 className="title text-ellipsis" title="Out of office">
                                                    Out of office
                                                </h6>
                                            </div>
                                            <div className="col-registration-status">
                                                <h6 className="title text-ellipsis" title="Registrations Status">
                                                    Registrations Status
                                                </h6>
                                            </div>
                                            <div className="col-actions">
                                                <h6 className="title text-ellipsis" title="Actions">
                                                    Actions
                                                </h6>
                                            </div>
                                        </div>
                                        <div style={{ width: searchedWorkTimeAnalytics?.length > 10 ? 18 : 0 }} />
                                    </div>

                                    {/* Table Body */}
                                    <div className="accordion accordion-registration accordion-first">
                                        {searchedWorkTimeAnalytics?.length > 0 ? (
                                            searchedWorkTimeAnalytics?.map((user, userIndex) =>
                                                user?.HoursWork > 0 &&
                                                <div key={userIndex} className={`accordion-item${accordionIndex[0] === userIndex ? " open" : ""}`}>
                                                    <h2 className="accordion-header">
                                                        <div className={`accordion-button py-0${accordionIndex[0] === userIndex ? "" : " collapsed"}`}>
                                                            <div className="d-flex align-items-center flex-wrap" style={{ flex: 1 }}>
                                                                <div
                                                                    className="col-employee"
                                                                    onClick={() => handleFistLevelSelect(userIndex)}
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#panelsStayOpen-collapse-${userIndex}`}
                                                                >
                                                                    <i className={`bi bi-chevron-${accordionIndex[0] === userIndex ? "up" : "down"}`} />
                                                                    <div className="flex-fill overflow-hidden">
                                                                        <span className="user-name text-ellipsis w-100">
                                                                            {user?.DisplayName ? user?.DisplayName : "--"}
                                                                        </span>
                                                                        <span className="user-email text-ellipsis w-100">
                                                                            {user?.Username ? user?.Username : "--"}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="col-total-hrs"
                                                                    onClick={() => handleFistLevelSelect(userIndex)}
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#panelsStayOpen-collapse-${userIndex}`}
                                                                >
                                                                    <span>
                                                                        {![undefined, null]?.includes(user?.HoursWork) ? `${user?.HoursWork?.toFixed(2)}`.replace(".", ",") : "--"}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className="col-rest-time"
                                                                    onClick={() => handleFistLevelSelect(userIndex)}
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#panelsStayOpen-collapse-${userIndex}`}
                                                                >
                                                                    <span>
                                                                        {![undefined, null]?.includes(user?.HoursRestMin) ? `${user?.HoursRestMin?.toFixed(2)}`.replace(".", ",") : "--"}
                                                                        {" / "}
                                                                        {![undefined, null]?.includes(user?.HoursRestMax) ? `${user?.HoursRestMax?.toFixed(2)}`.replace(".", ",") : "--"}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className="col-working-hrs"
                                                                    onClick={() => handleFistLevelSelect(userIndex)}
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#panelsStayOpen-collapse-${userIndex}`}
                                                                >
                                                                    <span>
                                                                        {![undefined, null]?.includes(user?.HoursWorkAverage) ? `${user?.HoursWorkAverage?.toFixed(2)}`.replace(".", ",") : "--"} hrs.
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className="col-out-of-office"
                                                                    onClick={() => handleFistLevelSelect(userIndex)}
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#panelsStayOpen-collapse-${userIndex}`}
                                                                >
                                                                    <span>
                                                                        {![undefined, null]?.includes(user?.AbsenceTotal) ? user?.AbsenceTotal : "--"}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className="col-registration-status"
                                                                    onClick={() => handleFistLevelSelect(userIndex)}
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#panelsStayOpen-collapse-${userIndex}`}
                                                                />
                                                                <div className="col-actions gap-2">
                                                                    {/* Submit All Button */}
                                                                    <button
                                                                        onClick={() => {
                                                                            if (user?.RegistrationsByDate?.length > 0) {
                                                                                confirmAlert({
                                                                                    customUI: ({ onClose }) => (
                                                                                        <div className="confirm-modal">
                                                                                            <div className="confirm-header">
                                                                                                <h1>Are you sure?</h1>
                                                                                            </div>

                                                                                            <div className="confirm-body">
                                                                                                {user?.RegistrationsByDate?.length > 2 ? (
                                                                                                    <>
                                                                                                        <p className="confirm-text">
                                                                                                            You want to <b>"SUBMIT"</b> on
                                                                                                        </p>
                                                                                                        <h4 className="mb-0 mt-2">
                                                                                                            {moment(user?.RegistrationsByDate[0]?.Date).format("Do MMM YYYY")} - {moment(user?.RegistrationsByDate?.at(-1)?.Date).format("Do MMM YYYY")}
                                                                                                        </h4>
                                                                                                    </>
                                                                                                ) : user?.RegistrationsByDate?.length === 1 && (
                                                                                                    <p className="confirm-text">
                                                                                                        You want to submit on {moment(user?.RegistrationsByDate[0]?.Date).format("DD-MM-YYYY")}?
                                                                                                    </p>
                                                                                                )}
                                                                                            </div>

                                                                                            <div className="confirm-footer">
                                                                                                <button onClick={() => onClose()} className="btn btn-cancel">
                                                                                                    No
                                                                                                </button>
                                                                                                <button
                                                                                                    onClick={() => {
                                                                                                        ChangeStatus(user?.Username, user?.RegistrationsByDate, true)
                                                                                                        onClose()
                                                                                                    }}
                                                                                                    className="btn btn-ok"
                                                                                                >
                                                                                                    Yes, Submit all
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        }}
                                                                        className="btn btn-submit-all"
                                                                    >
                                                                        Submit all
                                                                    </button>

                                                                    {/* Send Reminder Button */}
                                                                    {/* <button
                                                                        onClick={() =>
                                                                            confirmAlert({
                                                                                customUI: ({ onClose }) => (
                                                                                    <div className="confirm-modal">
                                                                                        <div className="confirm-header">
                                                                                            <h1>Are you sure?</h1>
                                                                                        </div>
                                                                                        <div className="confirm-body">
                                                                                            <p className="confirm-text">
                                                                                                You want to <b>"Send Reminder"</b> to <b>{
                                                                                                    user?.DisplayName
                                                                                                        ? user?.DisplayName
                                                                                                        : user?.Username
                                                                                                            ? user?.Username
                                                                                                            : "---"
                                                                                                }</b>?
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="confirm-footer">
                                                                                            <button onClick={() => onClose()} className="btn btn-cancel">
                                                                                                No
                                                                                            </button>
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    SendReminder(user?.Username)
                                                                                                    onClose()
                                                                                                }}
                                                                                                className="btn btn-ok"
                                                                                            >
                                                                                                Yes, Send it
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        className="btn btn-notify"
                                                                    >
                                                                        <i className="fa-regular fa-bell" />
                                                                    </button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </h2>

                                                    <div
                                                        id={`panelsStayOpen-collapse-${userIndex}`}
                                                        className={`accordion-collapse ${accordionIndex[0] === userIndex ? "collapsing" : "collapse"}`}
                                                    >
                                                        <div className="accordion-body p-0">
                                                            {user?.RegistrationsByDate?.length > 0 && (
                                                                <div className="accordion accordion-by-date">
                                                                    {user?.RegistrationsByDate?.map((byDate, byDateIndex) =>
                                                                        // byDate?.HoursWork > 0 &&
                                                                        <div key={byDateIndex} className={`accordion-item${accordionIndex[0] === userIndex && accordionIndex[1] === byDateIndex ? " open" : ""}`}>
                                                                            <h2 className="accordion-header">
                                                                                <div className={`accordion-button py-0${accordionIndex[0] === userIndex && accordionIndex[1] === byDateIndex ? "" : " collapsed"}`}>
                                                                                    <div
                                                                                        className="col-employee" style={{ paddingLeft: 20 }}
                                                                                        onClick={() => handleSecondLevelSelect(userIndex, byDateIndex)}
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${byDateIndex}`}
                                                                                    >
                                                                                        <i className={`bi bi-chevron-${accordionIndex[0] === userIndex && accordionIndex[1] === byDateIndex ? "up" : "down"}`} />
                                                                                        <span className="text-ellipsis">
                                                                                            {moment(byDate?.Date).format("DD-MM-YYYY, ddd")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-total-hrs"
                                                                                        onClick={() => handleSecondLevelSelect(userIndex, byDateIndex)}
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${byDateIndex}`}
                                                                                    >
                                                                                        <span>
                                                                                            {![undefined, null]?.includes(byDate?.HoursWork) ? `${byDate?.HoursWork?.toFixed(2)}`.replace(".", ",") : "--"}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-rest-time"
                                                                                        onClick={() => handleSecondLevelSelect(userIndex, byDateIndex)}
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${byDateIndex}`}
                                                                                    >
                                                                                        <span>
                                                                                            {![undefined, null]?.includes(byDate?.HoursRest) ? `${byDate?.HoursRest?.toFixed(2)}`.replace(".", ",") : "--"} hrs.
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-working-hrs"
                                                                                        onClick={() => handleSecondLevelSelect(userIndex, byDateIndex)}
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${byDateIndex}`}
                                                                                    >
                                                                                        {byDate?.IsSubmitted && (<span>
                                                                                            {![undefined, null]?.includes(byDate?.HoursWork) ? `${byDate?.HoursWork?.toFixed(2)}`.replace(".", ",") : "--"} hrs.
                                                                                        </span>)
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-out-of-office"
                                                                                        onClick={() => handleSecondLevelSelect(userIndex, byDateIndex)}
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${byDateIndex}`}
                                                                                    />

                                                                                    {/* Registration Status */}
                                                                                    <div
                                                                                        className="col-registration-status"
                                                                                        onClick={() => handleSecondLevelSelect(userIndex, byDateIndex)}
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${byDateIndex}`}
                                                                                    >
                                                                                        {byDate?.IsSubmitted ? (
                                                                                            <div className="badge badge-success">
                                                                                                Submitted
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="badge badge-warning">
                                                                                                Pending
                                                                                            </div>
                                                                                        )}
                                                                                    </div>

                                                                                    {/* Submit/ReOpen Registration Button */}
                                                                                    <div className="col-actions gap-2">
                                                                                        {byDate?.IsSubmitted ? (
                                                                                            <button
                                                                                                onClick={() =>
                                                                                                    confirmAlert({
                                                                                                        customUI: ({ onClose }) => (
                                                                                                            <div className="confirm-modal">
                                                                                                                <div className="confirm-header">
                                                                                                                    <h1>Are you sure?</h1>
                                                                                                                </div>
                                                                                                                <div className="confirm-body">
                                                                                                                    <p className="confirm-text">
                                                                                                                        You want to <b>"Reopen Submission"</b> on <b>{moment(byDate?.Date).format("Do MMM YYYY")}</b>?
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className="confirm-footer">
                                                                                                                    <button onClick={() => onClose()} className="btn btn-cancel">
                                                                                                                        No
                                                                                                                    </button>
                                                                                                                    <button
                                                                                                                        onClick={() => {
                                                                                                                            ChangeStatus(user?.Username, [byDate], false)
                                                                                                                            onClose()
                                                                                                                        }}
                                                                                                                        className="btn btn-ok"
                                                                                                                    >
                                                                                                                        Yes, Cancel it
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )

                                                                                                    })
                                                                                                }
                                                                                                className="btn btn-re-open"
                                                                                            >
                                                                                                Reopen
                                                                                            </button>
                                                                                        ) : (
                                                                                            <button
                                                                                                onClick={() =>
                                                                                                    confirmAlert({
                                                                                                        customUI: ({ onClose }) => (
                                                                                                            <div className="confirm-modal">
                                                                                                                <div className="confirm-header">
                                                                                                                    <h1>Are you sure?</h1>
                                                                                                                </div>
                                                                                                                <div className="confirm-body">
                                                                                                                    <p className="confirm-text">
                                                                                                                        You want to <b>"SUBMIT"</b> on <b>{moment(byDate?.Date).format("Do MMM YYYY")}</b>?
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className="confirm-footer">
                                                                                                                    <button onClick={() => onClose()} className="btn btn-cancel">
                                                                                                                        No
                                                                                                                    </button>
                                                                                                                    <button
                                                                                                                        onClick={() => {
                                                                                                                            ChangeStatus(user?.Username, [byDate], true)
                                                                                                                            onClose()
                                                                                                                        }}
                                                                                                                        className="btn btn-ok"
                                                                                                                    >
                                                                                                                        Yes, Submit
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                                className="btn btn-submit"
                                                                                            >
                                                                                                Submit
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </h2>

                                                                            <div
                                                                                id={`panelsStayOpen-collapse-${userIndex}-${byDateIndex}`}
                                                                                className={`accordion-collapse ${accordionIndex[0] === userIndex && accordionIndex[1] === byDateIndex ? "collapsing" : "collapse"}`}
                                                                            >
                                                                                <div className="accordion-body p-0">
                                                                                    {byDate?.Registrations?.length > 0 && (
                                                                                        byDate?.Registrations?.sort((a, b) => {
                                                                                            if (a.StartHour !== b.StartHour) {
                                                                                                return a.StartHour - b.StartHour
                                                                                            }
                                                                                            if (a.StartMinute !== b.StartMinute) {
                                                                                                return a.StartMinute - b.StartMinute
                                                                                            }
                                                                                            if (a.EndHour !== b.EndHour) {
                                                                                                return a.EndHour - b.EndHour
                                                                                            }
                                                                                            return a.EndMinute - b.EndMinute
                                                                                        }).map((byDateRegistration, byDateRegistrationIndex) =>
                                                                                            <div key={byDateRegistrationIndex} className="d-flex flex-wrap">
                                                                                                <div style={{ width: "25%", padding: "0.5rem 0  0.5rem 75px" }}>
                                                                                                    <span style={{ fontSize: "small", fontWeight: "500" }}>
                                                                                                        {![undefined, null].includes(byDateRegistration?.StartHour) && ![undefined, null].includes(byDateRegistration?.StartMinute)
                                                                                                            ? moment(byDateRegistration?.StartHour + ":" + byDateRegistration?.StartMinute, "HH:mm").format("HH:mm")
                                                                                                            : "--:--"
                                                                                                        }
                                                                                                        {" - "}
                                                                                                        {![undefined, null].includes(byDateRegistration?.EndHour) && ![undefined, null].includes(byDateRegistration?.EndMinute)
                                                                                                            ? moment(byDateRegistration?.EndHour + ":" + byDateRegistration?.EndMinute, "HH:mm").format("HH:mm")
                                                                                                            : "--:--"
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>

                                                                                                <div style={{ width: "75%", padding: "0.5rem 0  0.5rem 0px" }}>
                                                                                                    <span style={{ fontSize: "small", fontWeight: "500", color: "#909CAD" }}>
                                                                                                        {![undefined, null].includes(byDateRegistration?.StartHour) && ![undefined, null].includes(byDateRegistration?.StartMinute) &&
                                                                                                            ![undefined, null].includes(byDateRegistration?.EndHour) && ![undefined, null].includes(byDateRegistration?.EndMinute)
                                                                                                            ? `${calculateTimeDiff(
                                                                                                                `${byDateRegistration?.StartHour}:${byDateRegistration?.StartMinute}`,
                                                                                                                `${byDateRegistration?.EndHour}:${byDateRegistration?.EndMinute}`,
                                                                                                            )} hrs. - ${byDateRegistration?.RegistrationType?.Text ?? "--"}`
                                                                                                            : byDateRegistration?.RegistrationType?.Text ?? "--"
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className="no-record-found w-100 text-center p-3">
                                                <label>No Record Found!</label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {activeTable === 1 && (
                                <div className="card work-time-table-card violation-table-card mb-0">
                                    {/* Table Main Header */}
                                    <div className="work-time-table-card-header">
                                        <div className="d-flex align-items-center flex-wrap flex-fill">
                                            <div className="col-employee">
                                                <h6 className="title text-ellipsis" title="Employee" style={{ paddingLeft: 50 }}>
                                                    Employee
                                                </h6>
                                            </div>
                                            <div className="col-violation">
                                                <h6 className="title text-ellipsis" title="Violation/Rest time">
                                                    Violation/Rest time
                                                </h6>
                                            </div>
                                            <div className="col-status">
                                                <h6 className="title text-ellipsis" title="Status">
                                                    Status
                                                </h6>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Table Body */}
                                    <div className="accordion accordion-registration accordion-first">
                                        {searchedElevenHourRuleAnalytics?.length > 0 ? (
                                            searchedElevenHourRuleAnalytics?.map((user, userIndex) =>
                                                <div key={userIndex} className={`accordion-item${accordionIndex[0] === userIndex ? " open" : ""}`}>
                                                    <h2 className="accordion-header">
                                                        <div
                                                            className="accordion-button py-0 collapsed"
                                                            onClick={() => handleFistLevelSelect(userIndex)}
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#panelsStayOpen-collapse${userIndex}`}
                                                        >
                                                            <div className="d-flex align-items-center flex-wrap" style={{ flex: 1 }}>
                                                                <div className="col-employee">
                                                                    <i className={`bi bi-chevron-${accordionIndex[0] === userIndex ? "up" : "down"}`} />
                                                                    <div className="flex-fill overflow-hidden">
                                                                        <span className="user-name text-ellipsis w-100">
                                                                            {user?.DisplayName ? user?.DisplayName : "--"}
                                                                        </span>
                                                                        <span className="user-email text-ellipsis w-100">
                                                                            {user?.Username ? user?.Username : "--"}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-violation">
                                                                    <span>
                                                                        {![undefined, null]?.includes(user?.ViolationsTotal) ? user?.ViolationsTotal : "--"} Times
                                                                    </span>
                                                                </div>
                                                                <div className="col-status" />
                                                            </div>
                                                        </div>
                                                    </h2>

                                                    <div
                                                        id={`panelsStayOpen-collapse${userIndex}`}
                                                        className={`accordion-collapse ${accordionIndex[0] === userIndex ? "collapsing" : "collapse"}`}
                                                    >
                                                        <div className="accordion-body p-0">
                                                            {user?.Violations?.length > 0 && (
                                                                <div className="accordion accordion-by-date">
                                                                    {user?.Violations?.map((violation, violationIndex) =>
                                                                        <div key={`${userIndex}.${violationIndex}`} className={`accordion-item${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex ? " open" : ""}`}>
                                                                            <h2 className="accordion-header">
                                                                                <div
                                                                                    className={`accordion-button py-0${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex ? "" : " collapsed"}`}
                                                                                    onClick={() => handleSecondLevelSelect(userIndex, violationIndex)}
                                                                                >
                                                                                    <div
                                                                                        className="col-employee"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}`}
                                                                                        style={{ paddingLeft: 20 }}
                                                                                    >
                                                                                        <i className={`bi bi-chevron-${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex ? "up" : "down"}`} />
                                                                                        <span className="text-ellipsis">
                                                                                            {moment(violation?.DateFrom).format("DD-MM-YYYY, ddd")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-violation"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}`}
                                                                                    >
                                                                                        <span style={{ color: "var(--danger-color)" }}>
                                                                                            {![undefined, null]?.includes(violation?.Hours)
                                                                                                ? (`${violation?.Hours?.toFixed(2)}`).replace(".", ",")
                                                                                                : "--"
                                                                                            } hrs.
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-status"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}`}
                                                                                    />
                                                                                </div>
                                                                            </h2>

                                                                            <div
                                                                                id={`panelsStayOpen-collapse-${userIndex}-${violationIndex}`}
                                                                                className={`accordion-collapse ${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex ? "collapsing" : "collapse"}`}
                                                                            >
                                                                                <div className="accordion-body p-0">
                                                                                    {violation?.RegistrationsByDate?.length > 0 && (
                                                                                        <div className="accordion accordion-by-date">
                                                                                            {violation?.RegistrationsByDate?.map((byDate, byDateIndex) =>
                                                                                                <div key={`${userIndex}.${violationIndex}.${byDateIndex}`} className={`accordion-item${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex && accordionIndex[2] === byDateIndex ? " open" : ""}`}>
                                                                                                    <h2 className="accordion-header">
                                                                                                        <div
                                                                                                            className={`accordion-button py-0${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex && accordionIndex[2] === byDateIndex ? "" : " collapsed"}`}
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col-employee"
                                                                                                                onClick={() => handleThirdLevelSelect(userIndex, violationIndex, byDateIndex)}
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}-${byDateIndex}`}
                                                                                                                style={{ paddingLeft: 40 }}
                                                                                                            >
                                                                                                                <i className={`bi bi-chevron-${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex && accordionIndex[2] === byDateIndex ? "up" : "down"}`} />
                                                                                                                <span className="text-ellipsis">
                                                                                                                    {moment(byDate?.Date).format("DD-MM-YYYY")}, {byDate?.NameOfDay}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-violation"
                                                                                                                onClick={() => handleThirdLevelSelect(userIndex, violationIndex, byDateIndex)}
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}-${byDateIndex}`}
                                                                                                            >
                                                                                                                <span>{byDate?.Hours?.toFixed(2)} hrs.</span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-status"
                                                                                                                onClick={() => handleThirdLevelSelect(userIndex, violationIndex, byDateIndex)}
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}-${byDateIndex}`}
                                                                                                            >
                                                                                                                {byDate?.IsSubmitted ? (
                                                                                                                    <div className="badge badge-success">
                                                                                                                        Submitted
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div className="badge badge-warning">
                                                                                                                        Pending
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </h2>

                                                                                                    <div
                                                                                                        id={`panelsStayOpen-collapse-${userIndex}-${violationIndex}-${byDateIndex}`}
                                                                                                        className={`accordion-collapse ${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex && accordionIndex[2] === byDateIndex ? "collapsing" : "collapse"}`}
                                                                                                    >
                                                                                                        <div className="accordion-body p-0">
                                                                                                            {byDate?.Registrations?.length > 0 && (
                                                                                                                byDate?.Registrations?.map((registration, registrationIndex) =>
                                                                                                                    <div key={`${userIndex}.${byDateIndex}.${registrationIndex}`} className="d-flex flex-wrap align-items-center">
                                                                                                                        <div style={{ width: "35%", padding: "0.5rem 0  0.5rem 95px" }}>
                                                                                                                            <span style={{ fontSize: "small", fontWeight: "500" }}>
                                                                                                                                {moment(registration?.StartHour + ":" + registration?.StartMinute, "HH:mm").format("HH:mm")}
                                                                                                                                &nbsp;-&nbsp;
                                                                                                                                {moment(registration?.EndHour + ":" + registration?.EndMinute, "HH:mm").format("HH:mm")}
                                                                                                                            </span>
                                                                                                                        </div>

                                                                                                                        <div style={{ width: "65%" }}>
                                                                                                                            <span style={{ fontSize: "small", fontWeight: "500", color: "#909CAD" }}>
                                                                                                                                {![undefined, null].includes(registration?.StartHour) && ![undefined, null].includes(registration?.StartMinute) &&
                                                                                                                                    ![undefined, null].includes(registration?.EndHour) && ![undefined, null].includes(registration?.EndMinute)
                                                                                                                                    ? `${calculateTimeDiff(
                                                                                                                                        `${registration?.StartHour}:${registration?.StartMinute}`,
                                                                                                                                        `${registration?.EndHour}:${registration?.EndMinute}`,
                                                                                                                                    )} hrs. - ${registration?.RegistrationType?.Text ?? "--"}`
                                                                                                                                    : registration?.RegistrationType?.Text ?? "--"
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className="no-record-found w-100 text-center p-3">
                                                <label>No Record Found!</label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {activeTable === 2 && (
                                <div className="card work-time-table-card violation-table-card mb-0">
                                    {/* Table Main Header */}
                                    <div className="work-time-table-card-header">
                                        <div className="d-flex align-items-center flex-wrap flex-fill">
                                            <div className="col-employee">
                                                <h6 className="title text-ellipsis" title="Employee" style={{ paddingLeft: 50 }}>
                                                    Employee
                                                </h6>
                                            </div>
                                            <div className="col-violation">
                                                <h6 className="title text-ellipsis" title="Violation/Rest time">
                                                    Violation/Rest time
                                                </h6>
                                            </div>
                                            <div className="col-status">
                                                <h6 className="title text-ellipsis" title="Status">
                                                    Status
                                                </h6>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Table Body */}
                                    <div className="accordion accordion-registration accordion-first">
                                        {searchedFortyEightHoursAnalytics?.length > 0 ? (
                                            searchedFortyEightHoursAnalytics?.map((user, userIndex) =>
                                                <div key={userIndex} className={`accordion-item${accordionIndex[0] === userIndex ? " open" : ""}`}>
                                                    <h2 className="accordion-header">
                                                        <div
                                                            className="accordion-button py-0 collapsed"
                                                            onClick={() => handleFistLevelSelect(userIndex)}
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#panelsStayOpen-collapse${userIndex}`}
                                                        >
                                                            <div className="d-flex align-items-center flex-wrap" style={{ flex: 1 }}>
                                                                <div className="col-employee">
                                                                    <i className={`bi bi-chevron-${accordionIndex[0] === userIndex ? "up" : "down"}`} />
                                                                    <div className="flex-fill overflow-hidden">
                                                                        <span className="user-name text-ellipsis w-100">
                                                                            {user?.DisplayName ? user?.DisplayName : "--"}
                                                                        </span>
                                                                        <span className="user-email text-ellipsis w-100">
                                                                            {user?.Username ? user?.Username : "--"}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-violation">
                                                                    <span>
                                                                        {![undefined, null]?.includes(user?.ViolationsTotal) ? user?.ViolationsTotal : "--"} Times
                                                                    </span>
                                                                </div>
                                                                <div className="col-status" />
                                                            </div>
                                                        </div>
                                                    </h2>

                                                    <div
                                                        id={`panelsStayOpen-collapse${userIndex}`}
                                                        className={`accordion-collapse ${accordionIndex[0] === userIndex ? "collapsing" : "collapse"}`}
                                                    >
                                                        <div className="accordion-body p-0">
                                                            {user?.Violations?.length > 0 && (
                                                                <div className="accordion accordion-by-date">
                                                                    {user?.Violations?.map((violation, violationIndex) =>
                                                                        <div key={`${userIndex}.${violationIndex}`} className={`accordion-item${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex ? " open" : ""}`}>
                                                                            <h2 className="accordion-header">
                                                                                <div
                                                                                    className={`accordion-button py-0${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex ? "" : " collapsed"}`}
                                                                                    onClick={() => handleSecondLevelSelect(userIndex, violationIndex)}
                                                                                >
                                                                                    <div
                                                                                        className="col-employee"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}`}
                                                                                        style={{ paddingLeft: 20 }}
                                                                                    >
                                                                                        <i className={`bi bi-chevron-${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex ? "up" : "down"}`} />
                                                                                        <span className="text-ellipsis">
                                                                                            {`Week ${moment(violation?.DateFrom).week()} - ${moment(violation?.DateFrom).format(
                                                                                                moment(violation?.DateFrom).format("MM") !== moment(violation?.DateTo).format("MM") && moment(violation?.DateFrom).format("YYYY") !== moment(violation?.DateTo).format("YYYY")
                                                                                                    ? "DD MMM, YYYY"
                                                                                                    : moment(violation?.DateFrom).format("MM") !== moment(violation?.DateTo).format("MM")
                                                                                                        ? "DD MMM"
                                                                                                        : "DD"
                                                                                            )} to ${moment(violation?.DateFrom).week() !== moment(violation?.DateTo).week()
                                                                                                ? "Week " + moment(violation?.DateTo).week() + " - "
                                                                                                : ""
                                                                                                }${moment(violation?.DateTo).format("DD")} ${moment(violation?.DateTo).format("MMM, YYYY")}`}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-violation"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}`}
                                                                                    >
                                                                                        <span style={{ color: "var(--danger-color)" }}>
                                                                                            {![undefined, null]?.includes(violation?.Hours)
                                                                                                ? (`${violation?.Hours?.toFixed(2)}`).replace(".", ",")
                                                                                                : "--"
                                                                                            } hrs.
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-status"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}`}
                                                                                    />
                                                                                </div>
                                                                            </h2>

                                                                            <div
                                                                                id={`panelsStayOpen-collapse-${userIndex}-${violationIndex}`}
                                                                                className={`accordion-collapse ${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex ? "collapsing" : "collapse"}`}
                                                                            >
                                                                                <div className="accordion-body p-0">
                                                                                    {violation?.RegistrationsByDate?.length > 0 && (
                                                                                        <div className="accordion accordion-by-date">
                                                                                            {violation?.RegistrationsByDate?.map((byDate, byDateIndex) =>
                                                                                                <div key={`${userIndex}.${violationIndex}.${byDateIndex}`} className={`accordion-item${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex && accordionIndex[2] === byDateIndex ? " open" : ""}`}>
                                                                                                    <h2 className="accordion-header">
                                                                                                        <div
                                                                                                            className={`accordion-button py-0${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex && accordionIndex[2] === byDateIndex ? "" : " collapsed"}`}
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col-employee"
                                                                                                                onClick={() => handleThirdLevelSelect(userIndex, violationIndex, byDateIndex)}
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}-${byDateIndex}`}
                                                                                                                style={{ paddingLeft: 40 }}
                                                                                                            >
                                                                                                                <i className={`bi bi-chevron-${accordionIndex[0] === userIndex && accordionIndex[1] === violationIndex && accordionIndex[2] === byDateIndex ? "up" : "down"}`} />
                                                                                                                <span className="text-ellipsis">
                                                                                                                    {moment(byDate?.Date).format("DD-MM-YYYY")}, {byDate?.NameOfDay}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-violation"
                                                                                                                onClick={() => handleThirdLevelSelect(userIndex, violationIndex, byDateIndex)}
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}-${byDateIndex}`}
                                                                                                            >
                                                                                                                <span>{byDate?.Hours?.toFixed(2)} hrs.</span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-status"
                                                                                                                onClick={() => handleThirdLevelSelect(userIndex, violationIndex, byDateIndex)}
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target={`#panelsStayOpen-collapse-${userIndex}-${violationIndex}-${byDateIndex}`}
                                                                                                            >
                                                                                                                {byDate?.IsSubmitted ? (
                                                                                                                    <div className="badge badge-success">
                                                                                                                        Submitted
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div className="badge badge-warning">
                                                                                                                        Pending
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </h2>

                                                                                                    <div
                                                                                                        id={`panelsStayOpen-collapse-${userIndex}-${violationIndex}-${byDateIndex}`}
                                                                                                        className="accordion-collapse collapse"
                                                                                                    >
                                                                                                        <div className="accordion-body p-0">
                                                                                                            {byDate?.Registrations?.length > 0 && (
                                                                                                                byDate?.Registrations?.map((registration, registrationIndex) =>
                                                                                                                    <div key={`${userIndex}.${byDateIndex}.${registrationIndex}`} className="d-flex flex-wrap align-items-center">
                                                                                                                        <div style={{ width: "35%", padding: "0.5rem 0  0.5rem 95px" }}>
                                                                                                                            <span style={{ fontSize: "small", fontWeight: "500" }}>
                                                                                                                                {moment(registration?.StartHour + ":" + registration?.StartMinute, "HH:mm").format("HH:mm")}
                                                                                                                                &nbsp;-&nbsp;
                                                                                                                                {moment(registration?.EndHour + ":" + registration?.EndMinute, "HH:mm").format("HH:mm")}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div style={{ width: "65%" }}>
                                                                                                                            <span style={{ fontSize: "small", fontWeight: "500", color: "#909CAD" }}>
                                                                                                                                {![undefined, null].includes(registration?.StartHour) && ![undefined, null].includes(registration?.StartMinute) &&
                                                                                                                                    ![undefined, null].includes(registration?.EndHour) && ![undefined, null].includes(registration?.EndMinute)
                                                                                                                                    ? `${calculateTimeDiff(
                                                                                                                                        `${registration?.StartHour}:${registration?.StartMinute}`,
                                                                                                                                        `${registration?.EndHour}:${registration?.EndMinute}`,
                                                                                                                                    )} hrs. - ${registration?.RegistrationType?.Text ?? "--"}`
                                                                                                                                    : registration?.RegistrationType?.Text ?? "--"
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className="no-record-found w-100 text-center p-3">
                                                <label>No Record Found!</label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* ================================ Don't Touch This! ================================ */}
                {/* =============================== Table To Be Exported ============================== */}
                <table ref={allRegRef} style={{ display: "none" }}>
                    <tbody>
                        <tr>
                            <th>Date</th>
                            <th>Employee Name</th>
                            <th>Employee Email</th>
                            <th>Time</th>
                            <th>Type</th>
                            <th>Total Hours</th>
                            <th>Daily Total Hours</th>
                            <th>Hours</th>
                            <th>Daily Rest Time</th>
                            <th>Rest time Min/Max</th>
                            <th>Average Working hours</th>
                            <th>Out of office</th>
                            <th>Status</th>
                        </tr>

                        {searchedWorkTimeAnalytics?.length > 0 && (
                            searchedWorkTimeAnalytics?.map((user, userIndex) => user?.RegistrationsByDate?.length > 0 && user?.HoursWork > 0 && (
                                user?.RegistrationsByDate?.map((byDate, byDateIndex) =>
                                    byDate?.Registrations?.length > 0 && (
                                        byDate?.Registrations?.map((byDateRegistration, byDateRegistrationIndex) =>
                                            <tr key={`${userIndex}${byDateIndex}${byDateRegistrationIndex}`}>
                                                <td>{moment(byDate?.Date).format("DD-MM-YYYY, ddd")}</td>
                                                <td>{user?.DisplayName ?? "--"}</td>
                                                <td>{user?.Username ?? "--"}</td>
                                                <td>
                                                    {![undefined, null]?.includes(byDateRegistration?.StartHour) && ![undefined, null]?.includes(byDateRegistration?.StartHour)
                                                        ? moment(byDateRegistration?.StartHour + ":" + byDateRegistration?.StartMinute, "HH:mm").format("HH:mm")
                                                        : "--:--"
                                                    }
                                                    {" - "}
                                                    {![undefined, null]?.includes(byDateRegistration?.StartHour) && ![undefined, null]?.includes(byDateRegistration?.StartHour)
                                                        ? moment(byDateRegistration?.EndHour + ":" + byDateRegistration?.EndMinute, "HH:mm").format("HH:mm")
                                                        : "--:--"
                                                    }
                                                </td>
                                                <td>{byDateRegistration?.RegistrationType?.Text}</td>
                                                <td>{![undefined, null]?.includes(user?.HoursWork) ? (`${user?.HoursWork?.toFixed(2)}`)?.replace(".", ",") : "--"}</td>
                                                <td>{![undefined, null]?.includes(byDate?.HoursWork) ? (`${byDate?.HoursWork?.toFixed(2)}`)?.replace(".", ",") : "--"}</td>
                                                <td>
                                                    {![undefined, null].includes(byDateRegistration?.StartHour) && ![undefined, null].includes(byDateRegistration?.StartMinute) &&
                                                        ![undefined, null].includes(byDateRegistration?.EndHour) && ![undefined, null].includes(byDateRegistration?.EndMinute)
                                                        ? calculateTimeDiff(
                                                            `${byDateRegistration?.StartHour}:${byDateRegistration?.StartMinute}`,
                                                            `${byDateRegistration?.EndHour}:${byDateRegistration?.EndMinute}`
                                                        )
                                                        : "--"
                                                    }
                                                </td>
                                                <td>{![undefined, null]?.includes(byDate?.HoursRest) ? (`${byDate?.HoursRest?.toFixed(2)}`)?.replace(".", ",") : "--"}</td>
                                                <td>{user?.HoursRestMin ?? "--"}/{user?.HoursRestMax ?? "--"}</td>
                                                <td>{![undefined, null]?.includes(user?.HoursWorkAverage) ? (`${user?.HoursWorkAverage?.toFixed(2)}`)?.replace(".", ",") : "--"}</td>
                                                <td>{![undefined, null]?.includes(user?.AbsenceTotal) ? user?.AbsenceTotal : "--"}</td>
                                                <td>{byDate?.IsSubmitted ? "Submitted" : "Pending"}</td>
                                            </tr>
                                        )
                                    )
                                    // : (
                                    //     <tr key={`${userIndex}${byDateIndex}`}>
                                    //         <td>{moment(byDate?.Date).format("DD-MM-YYYY, ddd")}</td>
                                    //         <td>{user?.DisplayName ?? "--"}</td>
                                    //         <td>{user?.Username ?? "--"}</td>
                                    //         <td>--:-- - --:--</td>
                                    //         <td>--</td>
                                    //         <td>{![undefined, null]?.includes(user?.HoursWork) ? (`${user?.HoursWork?.toFixed(2)}`)?.replace(".", ",") : "--"}</td>
                                    //         <td>{![undefined, null]?.includes(byDate?.HoursWork) ? (`${byDate?.HoursWork?.toFixed(2)}`)?.replace(".", ",") : "--"}</td>
                                    //         <td>--</td>
                                    //         <td>{![undefined, null]?.includes(byDate?.HoursRest) ? (`${byDate?.HoursRest?.toFixed(2)}`)?.replace(".", ",") : "--"}</td>
                                    //         <td>{user?.HoursRestMin ?? "--"}/{user?.HoursRestMax ?? "--"}</td>
                                    //         <td>{![undefined, null]?.includes(user?.HoursWorkAverage) ? (`${user?.HoursWorkAverage?.toFixed(2)}`)?.replace(".", ",") : "--"}</td>
                                    //         <td>{![undefined, null]?.includes(user?.AbsenceTotal) ? user?.AbsenceTotal : "--"}</td>
                                    //         <td>{byDate?.IsSubmitted ? "Submitted" : "Pending"}</td>
                                    //     </tr>
                                    // )
                                )
                            ))
                        )}
                    </tbody>
                </table>

                <table ref={rest11hrsRef} style={{ display: "none" }}>
                    <tbody>
                        <tr>
                            <th>Date</th>
                            <th>Employee Name</th>
                            <th>Employee Email</th>
                            <th>Type</th>
                            <th>Violation</th>
                            <th>Rest time</th>
                            <th>Status</th>
                        </tr>

                        {/* {searchedElevenHourRuleAnalytics?.length > 0 && (
                            searchedElevenHourRuleAnalytics?.map((user, userIndex) => user?.Violations?.length > 0 && (
                                user?.Violations?.map((violation, violationIndex) => violation?.RegistrationsByDate?.length > 0 ? (
                                    violation?.RegistrationsByDate?.map((byDate, byDateIndex) => byDate?.Registrations?.length > 0 ? (
                                        byDate?.Registrations?.map((registration, registrationIndex) =>
                                            <tr key={`${userIndex}.${violationIndex}.${byDateIndex}.${registrationIndex}`}></tr>
                                        )
                                    ) : (
                                        <tr key={`${userIndex}.${violationIndex}.${byDateIndex}`}></tr>
                                    ))
                                ) : (
                                    <tr key={`${userIndex}.${violationIndex}`}></tr>
                                ))
                            ))
                        )} */}
                    </tbody>
                </table>

                <table ref={rule48hrsRef} style={{ display: "none" }}>
                    <tbody>
                        <tr>
                            <th>Date</th>
                            <th>Employee Name</th>
                            <th>Employee Email</th>
                            <th>Type</th>
                            <th>Violation</th>
                            <th>Rest time</th>
                            <th>Status</th>
                        </tr>

                        {/* {searchedFortyEightHoursAnalytics?.length > 0 && (
                            searchedFortyEightHoursAnalytics?.map((user, userIndex) => user?.Violations?.length > 0 && (
                                user?.Violations?.map((violation, violationIndex) => violation?.RegistrationsByDate?.length > 0 ? (
                                    violation?.RegistrationsByDate?.map((byDate, byDateIndex) => byDate?.Registrations?.length > 0 ? (
                                        byDate?.Registrations?.map((registration, registrationIndex) =>
                                            <tr key={`${userIndex}.${violationIndex}.${byDateIndex}.${registrationIndex}`}></tr>
                                        )
                                    ) : (
                                        <tr key={`${userIndex}.${violationIndex}.${byDateIndex}`}></tr>
                                    ))
                                ) : (
                                    <tr key={`${userIndex}.${violationIndex}`}></tr>
                                ))
                            ))
                        )} */}
                    </tbody>
                </table>
            </div>
        </>
    )
}
