import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  // Filtered States
  FilteredLocations: [],
  FilteredFloorPlans: [],
  FilteredMeetingRooms: [],
  FilteredAreas: [],
  FilteredDepartments: [],

  // Selected States
  SelectedLocations: [],
  SelectedFloorPlans: [],
  SelectedMeetingRooms: [],
  SelectedAreas: [],
  SelectedCateringDepartments: [],
  SelectedDepartments: [],
  SelectedCompanies: [],
  SelectedProducts: [],
}

export const FilterSlice = createSlice({
  name: "API",
  initialState,
  reducers: {
    // Filtered State's Reducer
    changeFilteredLocations: (state, action) => {
      typeof action.payload === "function"
        ? state.FilteredLocations = action.payload(state.FilteredLocations)
        : state.FilteredLocations = action.payload
    },
    changeFilteredFloorPlans: (state, action) => {
      typeof action.payload === "function"
        ? state.FilteredFloorPlans = action.payload(state.FilteredFloorPlans)
        : state.FilteredFloorPlans = action.payload
    },
    changeFilteredMeetingRooms: (state, action) => {
      typeof action.payload === "function"
        ? state.FilteredMeetingRooms = action.payload(state.FilteredMeetingRooms)
        : state.FilteredMeetingRooms = action.payload
    },
    changeFilteredAreas: (state, action) => {
      typeof action.payload === "function"
        ? state.FilteredAreas = action.payload(state.FilteredAreas)
        : state.FilteredAreas = action.payload
    },
    changeFilteredDepartments: (state, action) => {
      typeof action.payload === "function"
        ? state.FilteredDepartments = action.payload(state.FilteredDepartments)
        : state.FilteredDepartments = action.payload
    },

    // Selected State's Reducer
    changeSelectedLocations: (state, action) => {
      typeof action.payload === "function"
        ? state.SelectedLocations = action.payload(state.SelectedLocations)
        : state.SelectedLocations = action.payload
    },
    changeSelectedFloorPlans: (state, action) => {
      typeof action.payload === "function"
        ? state.SelectedFloorPlans = action.payload(state.SelectedFloorPlans)
        : state.SelectedFloorPlans = action.payload
    },
    changeSelectedMeetingRooms: (state, action) => {
      typeof action.payload === "function"
        ? state.SelectedMeetingRooms = action.payload(state.SelectedMeetingRooms)
        : state.SelectedMeetingRooms = action.payload
    },
    changeSelectedAreas: (state, action) => {
      typeof action.payload === "function"
        ? state.SelectedAreas = action.payload(state.SelectedAreas)
        : state.SelectedAreas = action.payload
    },
    changeSelectedCateringDepartments: (state, action) => {
      typeof action.payload === "function"
        ? state.SelectedCateringDepartments = action.payload(state.SelectedCateringDepartments)
        : state.SelectedCateringDepartments = action.payload
    },
    changeSelectedDepartments: (state, action) => {
      typeof action.payload === "function"
        ? state.SelectedDepartments = action.payload(state.SelectedDepartments)
        : state.SelectedDepartments = action.payload
    },
    changeSelectedCompanies: (state, action) => {
      typeof action.payload === "function"
        ? state.SelectedCompanies = action.payload(state.SelectedCompanies)
        : state.SelectedCompanies = action.payload
    },
    changeSelectedProducts: (state, action) => {
      typeof action.payload === "function"
        ? state.SelectedProducts = action.payload(state.SelectedProducts)
        : state.SelectedProducts = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  changeFilteredLocations,
  changeFilteredFloorPlans,
  changeFilteredMeetingRooms,
  changeFilteredAreas,
  changeFilteredDepartments,
  changeSelectedLocations,
  changeSelectedFloorPlans,
  changeSelectedMeetingRooms,
  changeSelectedAreas,
  changeSelectedCateringDepartments,
  changeSelectedDepartments,
  changeSelectedCompanies,
  changeSelectedProducts,
} = FilterSlice.actions

export default FilterSlice.reducer
