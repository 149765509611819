import React, { useCallback, useEffect, useMemo, useState } from "react"
import { changeActivePanel, changeAppThemeData, changeCurrentUser, changeEnableCanteen, changeEnableDeskBooking, changeEnableMeetingRoom, changeEnableWorkTime } from "./store/slices/APIResponseSlice"
import { changeAccessToken, changeUserName, changeUserEmail } from "./store/slices/MSALSlice"
import { InteractionRequiredAuthError, InteractionStatus, } from "@azure/msal-browser"
import { AuthenticatedTemplate, useIsAuthenticated, useMsal, } from "@azure/msal-react"
import { accessRoles, appVersion, handleError, scope } from "./const"
import { getCurrentUser } from "./services/ApiServices"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { loginRequest } from "./authConfig"
import { toast } from "react-hot-toast"
import moment from "moment"

import Loader from "./components/Loader/Loader"
import Header from "./components/Header/Header"
import Home from "./components/Home/Home"
import ErrorPage from "./components/ErrorPage/Error"

export default function App() {
  const accessToken = useSelector((state) => state.MSALAuth.accessToken)
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts, inProgress } = useMsal()

  const [intervalTimeForAccessToken, setIntervalTimeForAccessToken] = useState(3600000)

  const [isLoading, setIsLoading] = useState(true)
  const [isCurrentUserDataLoading, setIsCurrentUserDataLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(null)

  const [isAnalyticsShow, setIsAnalyticsShow] = useState(false)

  const dispatch = useDispatch()

  const setAccessTokenData = useCallback((accessTokenResponse) => {
    // Acquire token silent success
    const accessToken = accessTokenResponse.accessToken

    localStorage.setItem("token", accessToken)
    window.accessToken = accessToken
    window.expToken = accessTokenResponse.idTokenClaims.exp

    dispatch(changeAccessToken(accessToken))

    setIntervalTimeForAccessToken((moment(accessTokenResponse?.expiresOn).diff(moment()._d, "s") + 1) * 1000)
  }, [dispatch])

  // Create Shade of #Hex color (divide = 2)
  const getShadeOfColor = (hex, divide) => {
    // Converted #Hex to (R, G, B)
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    // Convert (R, G, B) to their respective shades
    const r2 = parseInt(r + (255 - r) / divide)
    const g2 = parseInt(g + (255 - g) / divide)
    const b2 = parseInt(b + (255 - b) / divide)

    // Convert (R, G, B) to (R => #Hex, B => #Hex, G => #Hex)
    const componentToHex = (c) => {
      var hex = c.toString(16)
      return hex.length === 1 ? "0" + hex : hex
    }

    return "#" + componentToHex(r2) + componentToHex(g2) + componentToHex(b2)
  }

  const getCurrentUserData = useCallback(async () => {
    if (accessToken) {
      setIsCurrentUserDataLoading(true)
      await getCurrentUser().then((response) => {
        if (response?.Email) {
          dispatch(changeCurrentUser(response))
          if ((response?.Roles ?? [])?.some((role) => accessRoles?.includes(role))) {
            dispatch(changeUserName(response?.Name))
            dispatch(changeUserEmail(response?.Email))
            dispatch(changeAppThemeData({
              AppLogo: response?.AppLogo ? response?.AppLogo : "",
              Theme: response.Theme ? response.Theme : {},
            }))
            if (response?.EnableMeetingRoom) {
              dispatch(changeActivePanel("Meeting"))
            } else if (response?.EnableDeskBooking) {
              dispatch(changeActivePanel("Desk"))
            } else if (response?.FeatureEnableWorkTime) {
              dispatch(changeActivePanel("WorkTime"))
            } else if (response?.EnableCanteen) {
              dispatch(changeActivePanel("Catering"))
            }
            dispatch(changeEnableMeetingRoom(response?.EnableMeetingRoom ? true : false))
            dispatch(changeEnableDeskBooking(response?.EnableDeskBooking ? true : false))
            dispatch(changeEnableWorkTime(response?.FeatureEnableWorkTime ? true : false))
            dispatch(changeEnableCanteen(response?.EnableCanteen ? true : false))
            setIsAnalyticsShow(response?.FeatureEnableAnalytics ? true : false)
            if (response?.Theme) {
              const root = document.querySelector(":root")
              root && root.style.setProperty("--app-theme-color", `#${response.Theme?.AppThemeColor}`)
              root && root.style.setProperty("--app-theme-50-color", getShadeOfColor(`#${response.Theme?.AppThemeColor}`, 1.3))
              root && root.style.setProperty("--app-theme-25-color", getShadeOfColor(getShadeOfColor(`#${response.Theme?.AppThemeColor}`, 1.3), 2))
            }
            setIsAdmin(true)
          } else {
            dispatch(changeUserName(response?.Name ? response?.Name : ""))
            dispatch(changeUserEmail(response?.Email ? response?.Email : ""))
            setIsAdmin(false)
          }
        } else {
          dispatch(changeCurrentUser({}))
          handleError(response)
        }
      }).catch((error) => {
        dispatch(changeCurrentUser({}))
        handleError(error)
      }).finally(() => {
        setIsCurrentUserDataLoading(false)
      })
    } else {
      setIsCurrentUserDataLoading(false)
    }

    return
  }, [dispatch, accessToken])

  const AppVersion = useCallback(() => (
    <div className="position-fixed py-1" style={{ paddingRight: 30, right: 0, bottom: 0, zIndex: 9999, fontSize: "small" }}>
      <small>{appVersion}</small>
    </div>
  ), [])

  useEffect(() => {
    const interval = setInterval(() => {
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          // Acquire token silent success
          const accessToken = accessTokenResponse.accessToken

          localStorage.setItem("token", accessToken)
          window.accessToken = accessToken
          window.expToken = accessTokenResponse.idTokenClaims.exp

          dispatch(changeAccessToken(accessToken))

          setIntervalTimeForAccessToken((moment(accessTokenResponse?.expiresOn).diff(moment()._d, "s") + 1) * 1000)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.error(error)
        })
      }
    }, intervalTimeForAccessToken)

    return () => { clearInterval(interval) }
  }, [accounts, inProgress, instance, setAccessTokenData, intervalTimeForAccessToken, dispatch])

  useEffect(() => {
    getCurrentUserData()
  }, [getCurrentUserData])

  useEffect(() => {
    dispatch(changeUserName(isAuthenticated ? accounts[0]?.name : ""))
    dispatch(changeUserEmail(isAuthenticated ? accounts[0]?.username : ""))
  }, [dispatch, isAuthenticated, accounts])

  // MSAL Authentication
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (isAuthenticated && accounts?.length > 0) {
        const accessTokenRequest = { scopes: [scope], account: accounts[0] }
        instance.acquireTokenSilent(accessTokenRequest).then((tokenResponse) => {
          setAccessTokenData(tokenResponse)
          setIsLoading(false)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(accessTokenRequest).then((tokenResponse) => {
              setAccessTokenData(tokenResponse)
            }).catch((error) => {
              console.error(error)
              toast.error(error)
            })
          }
        })
      } else {
        setIsLoading(true)
        instance.loginRedirect(loginRequest).catch((error) => {
          console.error(error)
          toast.error(error)
        })
      }
    }
  }, [inProgress, isAuthenticated, accounts, instance, setAccessTokenData])

  return (
    <BrowserRouter>
      <AuthenticatedTemplate>
        <div style={{ height: "100vh", width: "100%", overflow: "auto" }}>
          {!isLoading && !isCurrentUserDataLoading ? (
            isAdmin ? (
              isAnalyticsShow ? (
                <>
                  <Header />
                  <Home />
                </>
              ) : (
                <>
                  <ErrorPage code="401" message="You do not have permission to access this resource." />
                  <AppVersion />
                </>
              )
            ) : !isAdmin && (
              <>
                <ErrorPage code={401} message={"You do not have permission to access this resource."} />
                <AppVersion />
              </>
            )
          ) : (
            <div className="h-100 w-100">
              <Loader />
              <AppVersion />
            </div>
          )}
        </div>
      </AuthenticatedTemplate>
    </BrowserRouter>
  )
}
