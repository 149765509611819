import React, { useEffect, useMemo, useState } from "react"
import { changeFilteredAreas, changeFilteredDepartments, changeFilteredFloorPlans, changeFilteredLocations, changeSelectedAreas, changeSelectedFloorPlans, changeSelectedLocations } from "../../store/slices/FilterSlice"
import { getDesksAnalytics } from "../../services/ApiServices"
import { handleError, sortResponseByKey } from "../../const"
import { useDispatch, useSelector } from "react-redux"
import StackedBarChart from "../Charts/StackedBarChart/StackedBarChart"
import LineChart from "../Charts/LineChart/LineChart"
import BarChart from "../Charts/BarChart/BarChart"
import HeatMap from "../HeatMap/HeatMap"
import Loader from "../Loader/Loader"
import moment from "moment"

export default function Desk() {
  const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)

  const allLocations = useSelector((state) => state.APIResponse.AllLocations)
  const allFloorPlans = useSelector((state) => state.APIResponse.AllFloorPlans)
  const allAreas = useSelector((state) => state.APIResponse.AllAreas)

  const selectedLocations = useSelector((state) => state.Filter.SelectedLocations)
  const selectedFloorPlans = useSelector((state) => state.Filter.SelectedFloorPlans)
  const selectedAreas = useSelector((state) => state.Filter.SelectedAreas)

  const [isLoading, setIsLoading] = useState(false)
  const [deskAnalytics, setDeskAnalytics] = useState({})

  const dispatch = useDispatch()

  const handleOnAreaClick = (areaId, selectedLocations = [], selectedFloorPlans = [], selectedAreas = []) => {
    let newSelectedAreas = []
    if (!selectedAreas?.includes(areaId)) {
      newSelectedAreas = [...selectedAreas, areaId]
    } else if (selectedAreas?.includes(areaId)) {
      newSelectedAreas = selectedAreas?.filter((d) => d !== areaId)
    }
    // =======================================================================================
    // =======================================================================================
    const newFilteredLocations = allLocations && allLocations?.length > 0 ? (
      (allLocations ?? [])?.filter((location) => (
        newSelectedAreas && newSelectedAreas?.length > 0 ? (
          (newSelectedAreas ?? [])?.some((selectedArea) => (location?.Areas ?? [])?.some((locationArea) => locationArea?.Id === selectedArea))
        ) : (true)
      ))
    ) : ([])
    const newSelectedLocations = (newFilteredLocations ?? [])?.filter((location) => selectedLocations?.includes(location?.Id))?.map((location) => location?.Id)
    // =======================================================================================
    // =======================================================================================
    const newFilteredFloorPlans = (allFloorPlans ?? [])?.filter((floorPlan) => (
      newSelectedLocations?.length > 0 ? (
        (newSelectedLocations ?? [])?.some((selectedLocation) => selectedLocation === floorPlan?.Location?.Id)
      ) : (
        (newFilteredLocations ?? [])?.some((location) => location?.Id === floorPlan?.Location?.Id)
      )
    ))
    const newSelectedFloorPlans = (newFilteredFloorPlans ?? [])?.filter((floorPlan) => selectedFloorPlans?.includes(floorPlan?.Id))?.map((floorPlan) => floorPlan?.Id)
    // =======================================================================================
    // =======================================================================================
    dispatch(changeSelectedLocations(newSelectedLocations))
    dispatch(changeSelectedFloorPlans(newSelectedFloorPlans))
    dispatch(changeSelectedAreas(newSelectedAreas))

    dispatch(changeFilteredLocations(newFilteredLocations))
    dispatch(changeFilteredFloorPlans(newFilteredFloorPlans))
  }

  const requestPayload = useMemo(() => ({
    FromDate: ApiObjectState?.fromDate,
    ToDate: ApiObjectState?.toDate,
    locationIds: ApiObjectState?.locationIds,
    floorplanIds: ApiObjectState?.floorplanIds,
    areaIds: ApiObjectState?.areaIds,
    departments: ApiObjectState?.departments
  }), [
    ApiObjectState?.fromDate,
    ApiObjectState?.toDate,
    ApiObjectState?.locationIds,
    ApiObjectState?.floorplanIds,
    ApiObjectState?.areaIds,
    ApiObjectState?.departments,
  ])

  useEffect(() => {
    // Pls DON'T REMOVE THIS TIME OUT!!!
    const timeOut = setTimeout(() => {
      if (requestPayload?.departments?.length > 0) {
        const filteredLocations = deskAnalytics?.Areas?.length > 0 && allLocations?.length > 0 ? (
          (allLocations ?? [])?.filter((location) =>
            (location?.Areas ?? [])?.some((area) => (deskAnalytics?.Areas ?? [])?.some((d) => d?.Id === area?.Id))
          )
        ) : ([])
        dispatch(changeSelectedLocations((prev) => {
          if (!(prev ?? [])?.every((selectedLocation) => (filteredLocations ?? [])?.some((location) => location?.Id === selectedLocation))) {
            return (prev ?? [])?.filter((selectedLocation) => (filteredLocations ?? [])?.some((location) => location?.Id === selectedLocation))
          } else {
            return prev
          }
        }))
        const filteredFloorPlans = filteredLocations?.length > 0 && allFloorPlans?.length > 0 ? (
          (allFloorPlans ?? [])?.filter((floorPlan) => (
            (filteredLocations ?? [])?.some((location) => location?.Id === floorPlan?.Location?.Id)
          ))?.filter((floorPlan) => (
            selectedLocations?.length > 0 ? (
              (selectedLocations ?? [])?.some((selectedLocation) => selectedLocation === floorPlan?.Location?.Id)
            ) : (true)
          ))
        ) : ([])
        dispatch(changeSelectedFloorPlans((prev) => {
          if (!(prev ?? [])?.every((selectedFloorPlan) => (filteredFloorPlans ?? [])?.some((floorPlan) => floorPlan?.Id === selectedFloorPlan))) {
            return (prev ?? [])?.filter((selectedFloorPlan) => (filteredFloorPlans ?? [])?.some((floorPlan) => floorPlan?.Id === selectedFloorPlan))
          } else {
            return prev
          }
        }))
        const filteredAreas = filteredFloorPlans?.length > 0 && allAreas?.length > 0 ? (
          (allAreas ?? [])?.filter((area) => (
            (filteredFloorPlans ?? [])?.some((floorPlan) => (
              (floorPlan?.FloorplanAreas ?? [])?.some((floorPlanArea) => floorPlanArea?.Id === area?.Id)
            ))
          ))
        ) : ([])
        dispatch(changeSelectedAreas((prev) => {
          if (!(prev ?? [])?.every((selectedArea) => (filteredAreas ?? [])?.some((area) => area?.Id === selectedArea))) {
            return (prev ?? [])?.filter((selectedArea) => (filteredAreas ?? [])?.some((area) => area?.Id === selectedArea))
          } else {
            return prev
          }
        }))
        dispatch(changeFilteredLocations(filteredLocations))
        dispatch(changeFilteredFloorPlans(filteredFloorPlans))
        dispatch(changeFilteredAreas(filteredAreas))
      } else {
        dispatch(changeFilteredDepartments(deskAnalytics?.Departments ?? []))
        if (!requestPayload?.locationIds?.length && !requestPayload?.floorplanIds?.length && !requestPayload?.areaIds?.length) {
          dispatch(changeFilteredLocations(allLocations))
          dispatch(changeFilteredFloorPlans(allFloorPlans))
          dispatch(changeFilteredAreas(allAreas))
        }
      }
    }, 100);

    return () => {
      clearTimeout(timeOut)
    }
  }, [
    dispatch,
    allLocations,
    allFloorPlans,
    allAreas,
    selectedLocations,
    selectedFloorPlans,
    selectedAreas,
    requestPayload,
    deskAnalytics?.Areas,
    deskAnalytics?.Departments,
  ])

  // GET DESK ANALYTICS DATA
  useEffect(() => {
    let subscribed = true

    if (requestPayload?.FromDate && requestPayload?.ToDate) {
      setIsLoading(true)
      getDesksAnalytics(requestPayload).then((response) => {
        if (subscribed) {
          setDeskAnalytics(response)
        }
      }).catch((error) => {
        if (subscribed) {
          handleError(error)
          setDeskAnalytics({})
          // !requestPayload?.departments?.length && dispatch(changeFilteredDepartments([]))
          // requestPayload?.departments?.length > 0 && dispatch(changeFilteredAreas([]))
        }
      }).finally(() => {
        if (subscribed) {
          setIsLoading(false)
        }
      })
    }

    return () => {
      subscribed = false
    }
  }, [
    requestPayload
  ])

  return (
    <>
      {isLoading && <Loader />}

      <div className="tab-pane fade show active">
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Desk bookings</div>
                <div className="digit">{deskAnalytics?.SeatsBooked ?? 0}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Guest bookings</div>
                <div className="digit">{deskAnalytics?.GuestsInvited ?? 0}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Lunch bookings</div>
                <div className="digit">{deskAnalytics?.LunchReserved ?? 0}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Parking bookings</div>
                <div className="digit">{deskAnalytics?.ParkingReserved ?? 0}</div>
              </div>
            </div>
          </div>
        </div>

        {/* ======== Charts Start ======== */}
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body p-4 h300">
                <div className="row mb-1">
                  <div className="col-12">
                    <h4 className="mb-0">Area capacity usage</h4>
                  </div>
                </div>
                <StackedBarChart
                  graphToolTipCapacityLabel="Desk area capacity"
                  graphToolTipUtilizationLabel="Desk area usage"
                  graphData={sortResponseByKey(deskAnalytics?.UtilizationAreas?.Items ?? [], "Name")}
                  onBarClick={(areaId) => handleOnAreaClick(areaId, selectedLocations, selectedFloorPlans, selectedAreas)}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body p-4 h300">
                <div className="row mb-1">
                  <div className="col-6">
                    <h4 className="mb-0">Desk area usage</h4>
                  </div>
                  <div className="col-6">
                    <h2 className="text-end f300  mb-0">
                      <div className="smallTxt d-inline-block pr-2">
                        Average&nbsp;&nbsp;
                      </div>
                      <span>{(deskAnalytics?.UtilizationDesks?.Average ?? 0) + "%"}</span>
                    </h2>
                  </div>
                </div>
                <div className="w-100" style={{ height: "215px" }}>
                  <LineChart
                    graphToolTipLabel="Desk area usage"
                    graphLabel={(deskAnalytics?.UtilizationDesks?.Items ?? [])?.map((d) => moment(d?.Date).format("MMM Do"))}
                    graphData={(deskAnalytics?.UtilizationDesks?.Items ?? [])?.map((d) => d?.Utilization)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6">
            <div className="card">
              <div className="card-body p-4 h250">
                <div className="row  mb-0">
                  <div className="col-8">
                    <h4 className="mb-0">Hourly usage</h4>
                  </div>
                  <div className="col-4">
                    <h2 className="text-end f300">
                      {(deskAnalytics?.UtilizationHourly?.Average ?? 0) + "%"}
                    </h2>
                  </div>
                </div>
                <div className="w-100" style={{ height: "158px" }}>
                  <BarChart
                    graphToolTipLabel="Hourly usage"
                    graphLabel={(deskAnalytics?.UtilizationHourly?.Items ?? [])?.map((d) => d?.Hour)}
                    graphData={(deskAnalytics?.UtilizationHourly?.Items ?? [])?.map((d) => d?.Utilization)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6">
            <div className="card">
              <div className="card-body p-4 h250">
                <div className="row  mb-0">
                  <div className="col-8">
                    <h4 className="mb-0">Weekday usage</h4>
                  </div>
                  <div className="col-4">
                    <h2 className="text-end f300">
                      {(deskAnalytics?.UtilizationWeekday?.Average ?? 0) + "%"}
                    </h2>
                  </div>
                </div>
                <div className="w-100" style={{ height: "158px" }}>
                  <BarChart
                    graphToolTipLabel="Weekday usage"
                    graphLabel={(deskAnalytics?.UtilizationWeekday?.Items ?? [])?.map((d) => d?.Weekday)}
                    graphData={(deskAnalytics?.UtilizationWeekday?.Items ?? [])?.map((d) => d?.Utilization)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------- Charts End -------- */}

        <HeatMap Title="Desk heat map" renderImage="DeskSeats" />
      </div>
    </>
  )
}
