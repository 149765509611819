import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  authenticated: false,
  accessToken: "",
  userName: "",
  userEmail: "",
}

export const MSALSlice = createSlice({
  name: "MSAL",
  initialState,
  reducers: {
    changeAuthenticated: (state, action) => {
      typeof action.payload === "function"
        ? state.authenticated = action.payload(state.authenticated)
        : state.authenticated = action.payload
    },
    changeAccessToken: (state, action) => {
      typeof action.payload === "function"
        ? state.accessToken = action.payload(state.accessToken)
        : state.accessToken = action.payload
    },
    changeUserName: (state, action) => {
      typeof action.payload === "function"
        ? state.userName = action.payload(state.userName)
        : state.userName = action.payload
    },
    changeUserEmail: (state, action) => {
      typeof action.payload === "function"
        ? state.userEmail = action.payload(state.userEmail)
        : state.userEmail = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  changeAuthenticated,
  changeAccessToken,
  changeUserName,
  changeUserEmail,
} = MSALSlice.actions

export default MSALSlice.reducer
