import React, { useEffect, useState } from "react"
import { changeFilteredLocations, changeFilteredFloorPlans, changeFilteredMeetingRooms, changeFilteredAreas, changeSelectedLocations, changeSelectedFloorPlans, changeSelectedMeetingRooms, changeSelectedAreas, changeSelectedCateringDepartments, changeSelectedDepartments, changeSelectedCompanies, changeSelectedProducts, } from "../../store/slices/FilterSlice"
import { changeAPIObject, changeAllLocations, changeAllFloorPlans, changeAllMeetingRooms, changeAllAreas, changeAllProducts, changeAllCompanies, changeAllDepartments, changeAllCateringDepartments, } from "../../store/slices/APIResponseSlice"
import { getAdminLocation, getAdminFloorPlans, getMeetingRooms, getAllProducts, getAllCompanies, getAdminDepartments, getAllDepartments, } from "../../services/ApiServices"
import { handleError, sortResponseByKey } from "../../const"
import { useDispatch, useSelector } from "react-redux"
import ReactDatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import "./SideBar.css"

export default function SideBar({ inputDate, setInputDate }) {
  const activePanel = useSelector((state) => state.APIResponse.ActivePanel)
  // const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)
  const hasMeetingRoomPermission = useSelector((state) => state.APIResponse.EnableMeetingRoom)
  const hasCateringPermission = useSelector((state) => state.APIResponse.EnableCanteen)

  const allLocations = useSelector((state) => state.APIResponse.AllLocations)
  const allFloorPlans = useSelector((state) => state.APIResponse.AllFloorPlans)
  const allMeetingRooms = useSelector((state) => state.APIResponse.AllMeetingRooms)
  const allAreas = useSelector((state) => state.APIResponse.AllAreas)
  const allProducts = useSelector((state) => state.APIResponse.AllProducts)
  const allCompanies = useSelector((state) => state.APIResponse.AllCompanies)
  const allCateringDepartments = useSelector((state) => state.APIResponse.AllCateringDepartments)
  const allDepartments = useSelector((state) => state.APIResponse.AllDepartments)

  const filteredLocations = useSelector((state) => state.Filter.FilteredLocations)
  const filteredFloorPlans = useSelector((state) => state.Filter.FilteredFloorPlans)
  const filteredMeetingRooms = useSelector((state) => state.Filter.FilteredMeetingRooms)
  const filteredAreas = useSelector((state) => state.Filter.FilteredAreas)
  const filteredDepartments = useSelector((state) => state.Filter.FilteredDepartments)

  const selectedLocations = useSelector((state) => state.Filter.SelectedLocations)
  const selectedFloorPlans = useSelector((state) => state.Filter.SelectedFloorPlans)
  const selectedMeetingRooms = useSelector((state) => state.Filter.SelectedMeetingRooms)
  const selectedAreas = useSelector((state) => state.Filter.SelectedAreas)
  const selectedCateringDepartments = useSelector((state) => state.Filter.SelectedCateringDepartments)
  const selectedDepartments = useSelector((state) => state.Filter.SelectedDepartments)
  const selectedCompanies = useSelector((state) => state.Filter.SelectedCompanies)
  const selectedProducts = useSelector((state) => state.Filter.SelectedProducts)

  const [locationCount, setLocationCount] = useState(4)
  const [floorCount, setFloorCount] = useState(4)
  const [filteredMeetingRoomsCount, setFilteredMeetingRoomsCount] = useState(4)
  const [areaCount, setAreaCount] = useState(4)
  const [cateringDepartmentCount, setCateringDepartmentCount] = useState(4)
  const [departmentCount, setDepartmentCount] = useState(4)
  const [productCount, setProductCount] = useState(4)
  const [companiesCount, setCompaniesCount] = useState(4)

  const dispatch = useDispatch()

  const handleOnLocationClick = (locationId, selectedLocations = [], selectedFloorPlans = [], selectedMeetingRooms = [], selectedAreas = []) => {
    let newSelectedLocations = []
    if (!selectedLocations.includes(locationId)) {
      newSelectedLocations = [...selectedLocations, locationId]
    } else if (selectedLocations.includes(locationId)) {
      newSelectedLocations = selectedLocations.filter((d) => d !== locationId)
    }

    // =======================================================================================
    // =======================================================================================

    const newFilteredFloorPlans = allFloorPlans && allFloorPlans?.length > 0 ? (
      newSelectedLocations && newSelectedLocations?.length > 0 ? (
        allFloorPlans?.filter((d) => newSelectedLocations?.includes(d?.Location?.Id))
      ) : allFloorPlans
    ) : []
    let newSelectedFloorPlans = []
    newFilteredFloorPlans?.forEach((floorPlan) => {
      selectedFloorPlans?.includes(floorPlan?.Id) && newSelectedFloorPlans?.push(floorPlan?.Id)
    })

    // =======================================================================================
    // =======================================================================================

    let newFilteredMeetingRooms = []
    if (allMeetingRooms && allMeetingRooms.length > 0) {
      if (newSelectedLocations.length === 0 && selectedFloorPlans.length === 0) {
        newFilteredMeetingRooms = allMeetingRooms
      }

      if (newSelectedLocations.length > 0 && selectedFloorPlans.length === 0) {
        newFilteredMeetingRooms = allMeetingRooms.filter((d) => newSelectedLocations.includes(d?.Location?.Id))
      }

      if ((newSelectedLocations.length === 0 || newSelectedLocations.length > 0) && selectedFloorPlans.length > 0) {
        const tempSelectedFloorPlans = allFloorPlans.filter((d) => selectedFloorPlans.includes(d?.Id))
        tempSelectedFloorPlans.forEach((floorPlan) => {
          floorPlan?.FloorplanMeetingRooms && floorPlan?.FloorplanMeetingRooms.length > 0 && newFilteredMeetingRooms.push(...floorPlan?.FloorplanMeetingRooms)
        })
      }
    } else {
      newFilteredMeetingRooms = []
    }

    let newSelectedMeetingRooms = []
    newFilteredMeetingRooms.forEach((meetingRoom) => {
      selectedMeetingRooms.includes(meetingRoom?.Id) && newSelectedMeetingRooms.push(meetingRoom?.Id)
    })

    // =======================================================================================
    // =======================================================================================

    let newFilteredAreas = []
    if (newSelectedLocations.length === 0 && selectedFloorPlans.length === 0) {
      newFilteredAreas = allAreas && allAreas.length > 0 ? allAreas : []
    }

    if (newSelectedLocations.length > 0 && selectedFloorPlans.length === 0) {
      newFilteredAreas = allAreas && allAreas.length > 0 ? allAreas.filter((d) => newSelectedLocations.includes(d?.Location?.Id)) : []
    }

    if ((newSelectedLocations.length === 0 || newSelectedLocations.length > 0) && selectedFloorPlans.length > 0) {
      const tempSelectedFloorPlans = allFloorPlans.filter((d) => selectedFloorPlans.includes(d?.Id))
      tempSelectedFloorPlans.forEach((floorPlan) => {
        if (floorPlan?.FloorplanAreas && floorPlan?.FloorplanAreas.length > 0) {
          floorPlan?.FloorplanAreas.forEach((area) => {
            newFilteredAreas.push(area?.Area)
          })
        }
      })
    }

    let newSelectedAreas = []
    newFilteredAreas.forEach((meetingRoom) => {
      selectedAreas.includes(meetingRoom?.Id) && newSelectedAreas.push(meetingRoom?.Id)
    })

    // =======================================================================================
    // =======================================================================================

    dispatch(changeSelectedLocations(newSelectedLocations))
    dispatch(changeSelectedFloorPlans(newSelectedFloorPlans))
    dispatch(changeSelectedMeetingRooms(newSelectedMeetingRooms))
    dispatch(changeSelectedAreas(newSelectedAreas))

    dispatch(changeFilteredFloorPlans(sortResponseByKey(newFilteredFloorPlans, "Name")))
    dispatch(changeFilteredMeetingRooms(sortResponseByKey(newFilteredMeetingRooms, "Name")))
    dispatch(changeFilteredAreas(sortResponseByKey(newFilteredAreas, "Name")))
  }

  const handleOnFloorClick = (floorId, selectedLocations = [], selectedFloorPlans = [], selectedMeetingRooms = [], selectedAreas = []) => {
    let newSelectedFloorPlan = []
    if (!selectedFloorPlans.includes(floorId)) {
      newSelectedFloorPlan = [...selectedFloorPlans, floorId]
    } else if (selectedFloorPlans.includes(floorId)) {
      newSelectedFloorPlan = selectedFloorPlans.filter((d) => d !== floorId)
    }
    // =======================================================================================
    // =======================================================================================
    let newFilteredLocations = []
    let newFilteredFloorPlans = []
    if (selectedLocations.length === 0) {
      newFilteredFloorPlans = allFloorPlans.filter((d) => newSelectedFloorPlan.includes(d?.Id))
      if (newFilteredFloorPlans.length > 0) {
        newFilteredLocations = allLocations.filter((d) => newFilteredFloorPlans.map((floorplan) => floorplan.Location.Id).includes(d?.Id))
      } else if (newFilteredFloorPlans.length === 0) {
        newFilteredLocations = allLocations
      }
    } else if (selectedLocations.length > 0 && newFilteredFloorPlans.length === 0) {
      newFilteredLocations = allLocations
    }
    // =======================================================================================
    // =======================================================================================
    let newFilteredMeetingRooms = []
    if ((selectedLocations.length === 0 || selectedLocations.length > 0) && newSelectedFloorPlan.length > 0) {
      const tempSelectedFloorPlans = allFloorPlans.filter((d) => newSelectedFloorPlan.includes(d?.Id))
      tempSelectedFloorPlans.forEach((floorPlan) => {
        if (floorPlan?.FloorplanMeetingRooms && floorPlan?.FloorplanMeetingRooms.length > 0) {
          floorPlan?.FloorplanMeetingRooms.forEach((meetingRoom) => {
            newFilteredMeetingRooms.push({ ...meetingRoom, Id: meetingRoom?.RoomId })
          })
        }
      })
    }
    if (selectedLocations.length === 0 && newSelectedFloorPlan.length === 0) {
      newFilteredMeetingRooms = allMeetingRooms && allMeetingRooms.length > 0 ? allMeetingRooms : []
    }
    if (selectedLocations.length > 0 && newSelectedFloorPlan.length === 0) {
      newFilteredMeetingRooms = allMeetingRooms && allMeetingRooms.length > 0 ?
        allMeetingRooms.filter((d) => selectedLocations.includes(d?.Location?.Id)) : []
    }
    let newSelectedMeetingRooms = []
    newFilteredMeetingRooms.forEach((meetingRoom) => {
      selectedMeetingRooms.includes(meetingRoom?.Id) && newSelectedMeetingRooms.push(meetingRoom?.Id)
    })
    // =======================================================================================
    // =======================================================================================
    let newFilteredAreas = []
    if ((selectedLocations.length === 0 || selectedLocations.length > 0) && newSelectedFloorPlan.length > 0) {
      const tempSelectedFloorPlans = allFloorPlans.filter((d) => newSelectedFloorPlan.includes(d?.Id))
      tempSelectedFloorPlans.forEach((floorPlan) => {
        if (floorPlan?.FloorplanAreas && floorPlan?.FloorplanAreas.length > 0) {
          floorPlan?.FloorplanAreas.forEach((area) => {
            newFilteredAreas.push(area?.Area)
          })
        }
      })
    }
    if (selectedLocations.length === 0 && newSelectedFloorPlan.length === 0) {
      newFilteredAreas = allAreas && allAreas.length > 0 ? allAreas : []
    }
    if (selectedLocations.length > 0 && newSelectedFloorPlan.length === 0) {
      newFilteredAreas = allAreas && allAreas.length > 0 ?
        allAreas.filter((d) => selectedLocations.includes(d?.Location?.Id)) : []
    }
    let newSelectedAreas = []
    newFilteredAreas.forEach((area) => {
      selectedAreas.includes(area?.Id) && newSelectedAreas.push(area?.Id)
    })
    // =======================================================================================
    // =======================================================================================
    dispatch(changeSelectedFloorPlans(newSelectedFloorPlan))
    dispatch(changeSelectedMeetingRooms(newSelectedMeetingRooms))
    dispatch(changeSelectedAreas(newSelectedAreas))

    dispatch(changeFilteredLocations(sortResponseByKey(newFilteredLocations, "Name")))
    dispatch(changeFilteredMeetingRooms(sortResponseByKey(newFilteredMeetingRooms, "Name")))
    dispatch(changeFilteredAreas(sortResponseByKey(newFilteredAreas, "Name")))
  }

  const handleOnMeetingClick = (meetingRoomId, selectedLocations = [], selectedFloorPlans = [], selectedMeetingRooms = []) => {
    let newSelectedMeetingRooms = []
    if (!selectedMeetingRooms?.includes(meetingRoomId)) {
      newSelectedMeetingRooms = [...selectedMeetingRooms, meetingRoomId]
    } else if (selectedMeetingRooms.includes(meetingRoomId)) {
      newSelectedMeetingRooms = selectedMeetingRooms?.filter((d) => d !== meetingRoomId)
    }
    // =======================================================================================
    // =======================================================================================
    const temp = (allMeetingRooms ?? [])?.filter((meetingRoom) => (newSelectedMeetingRooms ?? [])?.some((d) => d === meetingRoom?.Id))
    const newFilteredLocations = allLocations && allLocations?.length > 0 ? (
      (allLocations ?? [])?.filter((location) => (
        temp && temp?.length > 0 ? (
          (temp ?? [])?.some((selectedMeetingRoom) => selectedMeetingRoom?.Location?.Id === location?.Id)
        ) : (true)
      ))
    ) : ([])
    const newSelectedLocations = (newFilteredLocations ?? [])?.filter((location) => selectedLocations?.includes(location?.Id))?.map((location) => location?.Id)
    // =======================================================================================
    // =======================================================================================
    const newFilteredFloorPlans = (allFloorPlans ?? [])?.filter((floorPlan) => (
      newSelectedLocations?.length > 0 ? (
        (newSelectedLocations ?? [])?.some((selectedLocation) => selectedLocation === floorPlan?.Location?.Id)
      ) : (
        (newFilteredLocations ?? [])?.some((location) => location?.Id === floorPlan?.Location?.Id)
      )
    ))
    const newSelectedFloorPlans = (newFilteredFloorPlans ?? [])?.filter((floorPlan) => selectedFloorPlans?.includes(floorPlan?.Id))?.map((floorPlan) => floorPlan?.Id)
    // =======================================================================================
    // =======================================================================================

    dispatch(changeSelectedLocations(newSelectedLocations))
    dispatch(changeSelectedFloorPlans(newSelectedFloorPlans))
    dispatch(changeSelectedMeetingRooms(newSelectedMeetingRooms))

    dispatch(changeFilteredLocations(newFilteredLocations))
    dispatch(changeFilteredFloorPlans(newFilteredFloorPlans))
  }

  const handleOnAreaClick = (areaId, selectedLocations = [], selectedFloorPlans = [], selectedAreas = []) => {
    let newSelectedAreas = []
    if (!selectedAreas?.includes(areaId)) {
      newSelectedAreas = [...selectedAreas, areaId]
    } else if (selectedAreas?.includes(areaId)) {
      newSelectedAreas = selectedAreas?.filter((d) => d !== areaId)
    }
    // =======================================================================================
    // =======================================================================================
    const newFilteredLocations = allLocations && allLocations?.length > 0 ? (
      (allLocations ?? [])?.filter((location) => (
        newSelectedAreas && newSelectedAreas?.length > 0 ? (
          (newSelectedAreas ?? [])?.some((selectedArea) => (location?.Areas ?? [])?.some((locationArea) => locationArea?.Id === selectedArea))
        ) : (true)
      ))
    ) : ([])
    const newSelectedLocations = (newFilteredLocations ?? [])?.filter((location) => selectedLocations?.includes(location?.Id))?.map((location) => location?.Id)
    // =======================================================================================
    // =======================================================================================
    const newFilteredFloorPlans = (allFloorPlans ?? [])?.filter((floorPlan) => (
      newSelectedLocations?.length > 0 ? (
        (newSelectedLocations ?? [])?.some((selectedLocation) => selectedLocation === floorPlan?.Location?.Id)
      ) : (
        (newFilteredLocations ?? [])?.some((location) => location?.Id === floorPlan?.Location?.Id)
      )
    ))
    const newSelectedFloorPlans = (newFilteredFloorPlans ?? [])?.filter((floorPlan) => selectedFloorPlans?.includes(floorPlan?.Id))?.map((floorPlan) => floorPlan?.Id)
    // =======================================================================================
    // =======================================================================================
    dispatch(changeSelectedLocations(newSelectedLocations))
    dispatch(changeSelectedFloorPlans(newSelectedFloorPlans))
    dispatch(changeSelectedAreas(newSelectedAreas))

    dispatch(changeFilteredLocations(newFilteredLocations))
    dispatch(changeFilteredFloorPlans(newFilteredFloorPlans))
  }

  const handleCostCenterClick = (costCenter, selectedCateringDepartments = []) => {
    let newSelectedCostCenter = []
    if (!selectedCateringDepartments.includes(costCenter)) {
      newSelectedCostCenter = [...selectedCateringDepartments, costCenter]
    } else if (selectedCateringDepartments.includes(costCenter)) {
      newSelectedCostCenter = selectedCateringDepartments.filter((d) => d !== costCenter)
    }
    dispatch(changeSelectedCateringDepartments(newSelectedCostCenter))
  }

  const handleDepartmentsClick = (department, selectedDepartment = []) => {
    let newSelectedDepartments = []
    if (!(selectedDepartment ?? [])?.includes(department)) {
      newSelectedDepartments = [...(selectedDepartment ?? []), department]
    } else if ((selectedDepartment ?? [])?.includes(department)) {
      newSelectedDepartments = (selectedDepartment ?? [])?.filter((d) => d !== department)
    }
    dispatch(changeSelectedDepartments(newSelectedDepartments))
  }

  const handleOnCompanyClick = (company, selectedCompanies = []) => {
    let newSelectedCompanies = []
    if (!selectedCompanies.includes(company)) {
      newSelectedCompanies = [...selectedCompanies, company]
    } else if (selectedCompanies.includes(company)) {
      newSelectedCompanies = selectedCompanies.filter((d) => d !== company)
    }
    dispatch(changeSelectedCompanies(newSelectedCompanies))
  }

  const handleProductsClick = (product, selectedProducts = []) => {
    let newSelectedProducts = []
    if (!selectedProducts.includes(product)) {
      newSelectedProducts = [...selectedProducts, product]
    } else if (selectedProducts.includes(product)) {
      newSelectedProducts = selectedProducts.filter((d) => d !== product)
    }
    dispatch(changeSelectedProducts(newSelectedProducts))
  }

  useEffect(() => {
    dispatch(changeAPIObject({
      fromDate: moment(inputDate?.From).format("YYYY-MM-DD"),
      toDate: moment(inputDate?.To).format("YYYY-MM-DD"),
      locationIds: selectedLocations,
      floorplanIds: selectedFloorPlans,
      companiesIds: selectedCompanies,
      productsIds: selectedProducts,
      meetingRoomIds: selectedMeetingRooms,
      areaIds: selectedAreas,
      costCenterIds: selectedCateringDepartments,
      departments: selectedDepartments,
    }))
  }, [
    inputDate?.From, inputDate?.To,
    selectedLocations, selectedFloorPlans,
    selectedAreas, selectedMeetingRooms,
    selectedCateringDepartments, selectedProducts,
    selectedCompanies, selectedDepartments,
    dispatch
  ])

  // Get All MeetingRooms
  useEffect(() => {
    let subscribed = true

    if (hasMeetingRoomPermission) {
      getMeetingRooms().then((response) => {
        if (subscribed) {
          if (response && typeof response === "object" && response?.length >= 0) {
            dispatch(changeAllMeetingRooms(sortResponseByKey(response, "Name")))
            dispatch(changeFilteredMeetingRooms(sortResponseByKey(response, "Name")))
          } else {
            dispatch(changeAllMeetingRooms((prev) => prev?.length > 0 ? [] : prev))
            dispatch(changeFilteredMeetingRooms((prev) => prev?.length > 0 ? [] : prev))
            handleError(response)
          }
        }
      }).catch((error) => {
        dispatch(changeAllMeetingRooms((prev) => prev?.length > 0 ? [] : prev))
        dispatch(changeFilteredMeetingRooms((prev) => prev?.length > 0 ? [] : prev))
        handleError(error)
      })
    } else {
      dispatch(changeAllMeetingRooms((prev) => prev?.length > 0 ? [] : prev))
      dispatch(changeFilteredMeetingRooms((prev) => prev?.length > 0 ? [] : prev))
    }

    return () => {
      subscribed = false
    }
  }, [dispatch, hasMeetingRoomPermission])

  // Get All Companies/Products/CateringDepartments
  useEffect(() => {
    let subscribed = true

    if (hasCateringPermission) {
      getAllCompanies().then((response) => {
        if (subscribed) {
          if (response && typeof response === "object" && response?.length >= 0) {
            dispatch(changeAllCompanies(sortResponseByKey(response, "Name")))
          } else {
            dispatch(changeAllCompanies((prev) => prev?.length > 0 ? [] : prev))
            handleError(response)
          }
        }
      }).catch((error) => {
        if (subscribed) {
          dispatch(changeAllCompanies((prev) => prev?.length > 0 ? [] : prev))
          handleError(error)
        }
      })

      getAllProducts().then((response) => {
        if (subscribed) {
          if (response && typeof response === "object" && response?.length >= 0) {
            dispatch(changeAllProducts(sortResponseByKey(response, "Name")))
          } else {
            dispatch(changeAllProducts((prev) => prev?.length > 0 ? [] : prev))
            handleError(response)
          }
        }
      }).catch((error) => {
        if (subscribed) {
          dispatch(changeAllProducts((prev) => prev?.length > 0 ? [] : prev))
          handleError(error)
        }
      })

      getAllDepartments().then((response) => {
        if (subscribed) {
          if (response && typeof response === "object" && response?.length >= 0) {
            dispatch(changeAllCateringDepartments(sortResponseByKey(response, "Name")?.filter((d) => d?.Active)))
          } else {
            dispatch(changeAllCateringDepartments((prev) => prev?.length > 0 ? [] : prev))
            handleError(response)
          }
        }
      }).catch((error) => {
        if (subscribed) {
          handleError(error)
          dispatch(changeAllCateringDepartments((prev) => prev?.length > 0 ? [] : prev))
        }
      })
    } else {
      dispatch(changeAllCompanies((prev) => prev?.length > 0 ? [] : prev))
      dispatch(changeAllProducts((prev) => prev?.length > 0 ? [] : prev))
      dispatch(changeAllCateringDepartments((prev) => prev?.length > 0 ? [] : prev))
    }

    return () => {
      subscribed = false
    }
  }, [dispatch, hasCateringPermission])

  // Get All Locations/FloorPlans/Departments
  useEffect(() => {
    let subscribed = true

    getAdminLocation().then((response) => {
      if (subscribed) {
        if (response && typeof response === "object" && response?.length >= 0) {
          dispatch(changeAllLocations(sortResponseByKey(response, "Name")))
          dispatch(changeFilteredLocations(sortResponseByKey(response, "Name")))
          let tempAllAreas = []
          response?.forEach((location) => {
            (location?.Areas ?? [])?.forEach((area) => {
              tempAllAreas.push({ ...area, Location: { Id: location?.Id } })
            })
          })
          dispatch(changeAllAreas(sortResponseByKey(tempAllAreas, "Name")))
          dispatch(changeFilteredAreas(sortResponseByKey(tempAllAreas, "Name")))
        } else {
          dispatch(changeAllLocations((prev) => prev?.length > 0 ? [] : prev))
          dispatch(changeFilteredLocations((prev) => prev?.length > 0 ? [] : prev))
          dispatch(changeAllAreas((prev) => prev?.length > 0 ? [] : prev))
          dispatch(changeFilteredAreas((prev) => prev?.length > 0 ? [] : prev))
          handleError(response)
        }
      }
    }).catch((error) => {
      if (subscribed) {
        dispatch(changeAllLocations((prev) => prev?.length > 0 ? [] : prev))
        dispatch(changeFilteredLocations((prev) => prev?.length > 0 ? [] : prev))
        dispatch(changeAllAreas((prev) => prev?.length > 0 ? [] : prev))
        dispatch(changeFilteredAreas((prev) => prev?.length > 0 ? [] : prev))
        handleError(error)
      }
    })

    getAdminFloorPlans().then((response) => {
      if (subscribed) {
        if (response && typeof response === "object" && response?.length >= 0) {
          dispatch(changeAllFloorPlans(sortResponseByKey(response, "Name")))
          dispatch(changeFilteredFloorPlans(sortResponseByKey(response, "Name")))
        } else {
          dispatch(changeAllFloorPlans((prev) => prev?.length > 0 ? [] : prev))
          dispatch(changeFilteredFloorPlans((prev) => prev?.length > 0 ? [] : prev))
          handleError(response)
        }
      }
    }).catch((error) => {
      if (subscribed) {
        dispatch(changeAllFloorPlans((prev) => prev?.length > 0 ? [] : prev))
        dispatch(changeFilteredFloorPlans((prev) => prev?.length > 0 ? [] : prev))
        handleError(error)
      }
    })

    getAdminDepartments().then((response) => {
      if (subscribed) {
        if (response && typeof response === "object" && response?.length >= 0) {
          dispatch(changeAllDepartments(sortResponseByKey(response, "Name")))
        } else {
          dispatch(changeAllDepartments((prev) => prev?.length > 0 ? [] : prev))
          handleError(response)
        }
      }
    }).catch((error) => {
      if (subscribed) {
        dispatch(changeAllDepartments((prev) => prev?.length > 0 ? [] : prev))
        handleError(error)
      }
    })


    return () => {
      subscribed = false
    }
  }, [dispatch])

  return (
    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 filterSec">
      <div className="card p-4">
        {/* ========================================= Period Start ========================================= */}
        {/* ========================================= Period Start ========================================= */}
        <h4 className="mb-3">Select period</h4>

        <h4 className="mb-2"><small>From</small></h4>
        <div className="has-validation mb-3">
          <ReactDatePicker
            type="date"
            calendarStartDay={1}
            selected={moment(inputDate?.From)._d}
            onChange={(e) => e && setInputDate((Prev) => ({ ...Prev, From: e }))}
            // maxDate={moment(inputDate?.To)._d}
            dateFormat="dd.MM.yyyy"
            className="form-control"
            placeholder="DD/MM/YYYY"
          />
        </div>

        <h4 className="mb-2"><small>To</small></h4>
        <div className="has-validation">
          <ReactDatePicker
            type="date"
            calendarStartDay={1}
            selected={moment(inputDate?.To)._d}
            onChange={(e) => e && setInputDate((Prev) => ({ ...Prev, To: e }))}
            minDate={moment(inputDate?.From)._d}
            // maxDate={moment()._d}
            dateFormat="dd.MM.yyyy"
            className="form-control"
            placeholder="DD/MM/YYYY"
          />
        </div>
        {/* ----------------------------------------- Period End ----------------------------------------- */}



        {/* ========================================= Company Start ========================================= */}
        {/* ========================================= Company Start ========================================= */}
        {activePanel === "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Company</h4>
            {allCompanies?.length > 0 && (
              allCompanies?.map((company, companyIndex) => companyIndex < companiesCount && (
                <div key={companyIndex} className="d-flex align-items-center mb-1" >
                  <label
                    onClick={() => handleOnCompanyClick(company?.Id, selectedCompanies)}

                    className={`w-100 py-1 px-0 sidebar-filter ${selectedCompanies?.includes(company?.Id) ? "selected" : ""}`}
                  >
                    {company?.Name ? company?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {companiesCount < allCompanies?.length ? (
              <label onClick={() => setCompaniesCount(allCompanies?.length)} className="mt-15 show-label sidebar-filter">
                Show ({allCompanies?.length - 4}) more
              </label>
            ) : allCompanies?.length > 4 && (
              <label onClick={() => setCompaniesCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Company End ----------------------------------------- */}


        {/* ========================================= Location Start ========================================= */}
        {/* ========================================= Location Start ========================================= */}
        {activePanel !== "WorkTime" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Locations</h4>
            {filteredLocations?.length > 0 && (
              filteredLocations?.map((location, locationIndex) => locationIndex < locationCount && (
                <div key={locationIndex} className="d-flex align-items-center mb-1" >
                  <label
                    onClick={() => handleOnLocationClick(location?.Id, selectedLocations, selectedFloorPlans, selectedMeetingRooms, selectedAreas)}
                    className={`w-100 py-1 px-0 sidebar-filter ${selectedLocations?.includes(location?.Id) ? "selected" : ""}`}
                  >
                    {location?.Name ? location?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {locationCount < filteredLocations?.length ? (
              <label onClick={() => setLocationCount(filteredLocations?.length)} className="mt-15 show-label sidebar-filter">
                Show ({filteredLocations?.length - 4}) more
              </label>
            ) : filteredLocations?.length > 4 && (
              <label onClick={() => setLocationCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Location End ----------------------------------------- */}



        {/* ========================================= Floor Plan Start ========================================= */}
        {/* ========================================= Floor Plan Start ========================================= */}
        {activePanel !== "WorkTime" && activePanel !== "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Floor plans</h4>
            {filteredFloorPlans?.length > 0 && (
              filteredFloorPlans?.map((floorPlan, floorPlanIndex) => floorPlanIndex < floorCount && (
                <div key={floorPlanIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleOnFloorClick(floorPlan?.Id, selectedLocations, selectedFloorPlans, selectedMeetingRooms, selectedAreas)}
                    className={`w-100 py-1 px-0 sidebar-filter ${selectedFloorPlans?.includes(floorPlan?.Id) ? "selected" : ""}`}
                  >
                    {floorPlan?.Name ? floorPlan?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {floorCount < filteredFloorPlans?.length ? (
              <label onClick={() => setFloorCount(filteredFloorPlans?.length)} className="mt-15 show-label sidebar-filter">
                Show ({filteredFloorPlans?.length - 4}) more
              </label>
            ) : filteredFloorPlans?.length > 4 && (
              <label onClick={() => setFloorCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Floor Plan End ----------------------------------------- */}



        {/* ========================================= MeetingRooms Start ========================================= */}
        {/* ========================================= MeetingRooms Start ========================================= */}
        {activePanel === "Meeting" && activePanel !== "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Meeting Rooms</h4>
            {filteredMeetingRooms?.length > 0 && (
              filteredMeetingRooms?.map((meetingRoom, meetingRoomIndex) => meetingRoomIndex < filteredMeetingRoomsCount && (
                <div key={meetingRoomIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleOnMeetingClick(meetingRoom?.Id, selectedLocations, selectedFloorPlans, selectedMeetingRooms)}
                    className={`w-100 py-1 px-0 sidebar-filter ${selectedMeetingRooms?.includes(meetingRoom?.Id) ? "selected" : ""}`}
                  >
                    {meetingRoom?.Name ? meetingRoom?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {filteredMeetingRoomsCount < filteredMeetingRooms?.length ? (
              <label onClick={() => setFilteredMeetingRoomsCount(filteredMeetingRooms?.length)} className="mt-15 show-label sidebar-filter">
                Show ({filteredMeetingRooms?.length - 4}) more
              </label>
            ) : filteredMeetingRooms?.length > 4 && (
              <label onClick={() => setFilteredMeetingRoomsCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- MeetingRooms End ----------------------------------------- */}



        {/* ========================================= Areas Start ========================================= */}
        {/* ========================================= Areas Start ========================================= */}
        {activePanel === "Desk" && activePanel !== "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Areas</h4>
            {filteredAreas?.length > 0 && (
              filteredAreas?.map((area, areaIndex) => areaIndex < areaCount && (
                <div key={areaIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleOnAreaClick(area?.Id, selectedLocations, selectedFloorPlans, selectedAreas)}
                    className={`w-100 py-1 px-0 sidebar-filter ${selectedAreas?.includes(area?.Id) ? "selected" : ""}`}
                  >
                    {area?.Name ? area?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {areaCount < filteredAreas?.length ? (
              <label onClick={() => setAreaCount(filteredAreas?.length)} className="mt-15 show-label sidebar-filter">
                Show ({filteredAreas?.length - 4}) more
              </label>
            ) : filteredAreas?.length > 4 && (
              <label onClick={() => setAreaCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Areas End ----------------------------------------- */}



        {/* ========================================= Departments Start ========================================= */}
        {/* ========================================= Departments Start ========================================= */}
        {(activePanel === "Desk") && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Departments</h4>
            {(selectedLocations?.length > 0 || selectedFloorPlans?.length > 0 || selectedAreas?.length > 0) ? (
              filteredDepartments && filteredDepartments?.length > 0 && (
                filteredDepartments?.map((department, departmentIndex) => departmentIndex < departmentCount && (
                  <div key={departmentIndex} className="d-flex align-items-center mb-1">
                    <label
                      onClick={() => handleDepartmentsClick(department, selectedDepartments)}
                      className={`w-100 py-1 px-0 sidebar-filter  ${selectedDepartments?.includes(department) ? "selected" : ""}`}
                    >
                      {department ?? "--"}
                    </label>
                  </div>
                ))
              )
            ) : (
              allDepartments?.length > 0 && (
                allDepartments?.map((department, departmentIndex) => departmentIndex < departmentCount && (
                  <div key={departmentIndex} className="d-flex align-items-center mb-1">
                    <label
                      onClick={() => handleDepartmentsClick(department, selectedDepartments)}
                      className={`w-100 py-1 px-0 sidebar-filter  ${selectedDepartments?.includes(department) ? "selected" : ""}`}
                    >
                      {department ?? "--"}
                    </label>
                  </div>
                ))
              )
            )}

            {departmentCount < allDepartments?.length ? (
              <label onClick={() => setDepartmentCount(allDepartments?.length)} className="mt-15 show-label sidebar-filter">
                Show ({allDepartments?.length - 4}) more
              </label>
            ) : allDepartments?.length > 4 && (
              <label onClick={() => setDepartmentCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Departments End ----------------------------------------- */}



        {/* ========================================= Cost center Start ========================================= */}
        {/* ========================================= Cost center Start ========================================= */}
        {activePanel === "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Costcenter</h4>
            {allCateringDepartments?.length > 0 && (
              allCateringDepartments?.filter((d) => d?.Active)?.map((department, departmentIndex) => departmentIndex < cateringDepartmentCount && (
                <div key={departmentIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleCostCenterClick(department?.Id, selectedCateringDepartments)}
                    className={`w-100 py-1 px-0 sidebar-filter  ${selectedCateringDepartments?.includes(department?.Id) ? "selected" : ""}`}
                  >
                    {department?.Name ? department?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {cateringDepartmentCount < allCateringDepartments?.length ? (
              <label onClick={() => setCateringDepartmentCount(allCateringDepartments?.length)} className="mt-15 show-label sidebar-filter">
                Show ({allCateringDepartments?.length - 4}) more
              </label>
            ) : allCateringDepartments?.length > 4 && (
              <label onClick={() => setCateringDepartmentCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Cost center End ----------------------------------------- */}



        {/* ========================================= Products Start ========================================= */}
        {/* ========================================= Products Start ========================================= */}
        {activePanel === "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Products</h4>
            {allProducts?.length > 0 && (
              allProducts?.map((product, productIndex) => productIndex < productCount && (
                <div key={productIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleProductsClick(product?.Id, selectedProducts)}
                    className={`w-100 py-1 px-0 sidebar-filter  ${selectedProducts?.includes(product?.Id) ? "selected" : ""}`}
                  >
                    {product?.Name ? product?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {productCount < allProducts?.length ? (
              <label onClick={() => setProductCount(allProducts?.length)} className="mt-15 show-label sidebar-filter">
                Show ({allProducts?.length - 4}) more
              </label>
            ) : allProducts?.length > 4 && (
              <label onClick={() => setProductCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Products End ----------------------------------------- */}
      </div>
    </div >
  )
}
